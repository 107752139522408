<div class="datatable responsive-table">
    <datatable-header pluralResourceName="files">
        <ng-container leftContent>
            <div class="title" headerActions trans>File Entries</div>
        </ng-container>
        <button mat-raised-button color="accent" [matTooltip]="'Delete selected' | trans" (click)="maybeDeleteSelectedEntries()" rightContent *ngIf="currentUser.hasPermission('files.delete')" trans>Delete Selected</button>
        <ng-template #tableFilters>
            <file-entry-index-filters></file-entry-index-filters>
        </ng-template>
    </datatable-header>
    <table class="material-table">
        <thead>
        <tr>
            <th table-header-checkbox></th>
            <th table-sort-header trans>Name</th>
            <th table-sort-header trans>File Name</th>
            <th table-sort-header="user_id" trans>Owner</th>
            <th table-sort-header trans>Type</th>
            <th table-sort-header trans>Public</th>
            <th table-sort-header trans>File Size</th>
            <th table-sort-header="created_at" trans>Uploaded At</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let file of files$ | async">
            <td [table-body-checkbox]="file.id"></td>
            <td data-label="Name">{{file.name}}</td>
            <td data-label="File Name">{{file.file_name}}</td>
            <td>
                <user-column [user]="file.users[0]"></user-column>
            </td>
            <td data-label="Type">{{file.type}}</td>
            <td data-label="Public">{{file.public ? 'Yes' : 'No'}}</td>
            <td data-label="File Size"> {{file.file_size ? (file.file_size | formattedFileSize) : '-'}} </td>
            <td>{{file.created_at | formattedDate}}</td>
        </tr>
        </tbody>
    </table>
    <no-results-message svgImage="file-searching.svg" *ngIf="datatable.paginator.noResults$ | async">
        <span primary-text trans>No files have been created yet</span>
    </no-results-message>
    <datatable-footer></datatable-footer>
</div>
