<auth-page>
    <form (ngSubmit)="sendPasswordResetLink()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div class="page-header" trans>Enter your email address below and we will send you a link to reset or create
            your password.</div>

        <div class="page-content">
            <div class="input-container">
                <label for="email" trans>Email</label>
                <input type="email" formControlName="email" id="email" required>
                <p class="error big-error" *ngIf="errors.email">
                    <mat-icon svgIcon="warning"></mat-icon>
                    <span trans>{{errors.email}}</span>
                </p>
                <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
                    class="error big-error">
                    <div *ngIf="form.controls['email'].errors.required" trans>
                        <mat-icon svgIcon="warning"></mat-icon> Email is required!
                    </div>
                    <div *ngIf="form.controls['email'].errors?.pattern" trans>
                        <mat-icon svgIcon="warning"></mat-icon> Invalid email!
                    </div>
                </div>
            </div>

            <button mat-raised-button class="primary-button" color="accent" type="submit" [disabled]="!form.valid || (loading$ | async)"
                trans>Continue</button>
        </div>
    </form>
</auth-page>
