<aside class="admin-pill-nav">
    <!-- <div class="new-localization" id="custom_localization_issue">
        <button mat-button (click)="showCrupdateLocalizationModal()" *ngIf="currentUser.hasPermission('localizations.create')">
            <mat-icon svgIcon="add"></mat-icon>
            <span trans>New Localization</span>
        </button>
    </div> -->

    <ng-container *ngIf="breakpoints.isMobile$ | async; else desktopNav">
        <button [matMenuTriggerFor]="navMenu" class="no-style admin-pill-nav-mobile-selector">
            <span class="name" trans>{{selectedLocalization?.model.name}}</span>
            <mat-icon svgIcon="keyboard-arrow-down"></mat-icon>
        </button>
        <mat-menu #navMenu="matMenu" class="admin-pill-nav-menu">
            <button mat-menu-item *ngFor="let localization of localizations$ | async" (click)="setSelectedLocalization(localization)" [class.selected]="selectedLocalization?.model.id == localization.model.id">{{localization.model.name}}</button>
        </mat-menu>
    </ng-container>

    <ng-template #desktopNav>
        <div class="nav-item" [@fadeIn] *ngFor="let localization of localizations$ | async; trackBy: trackByFn" (click)="setSelectedLocalization(localization)" [class.selected]="selectedLocalization?.model.id == localization.model.id">
            <div class="name">{{localization.model.name.replace('-', ' ')}}</div>

            <div class="actions">
                <button mat-icon-button (click)="$event.stopPropagation()" [matMenuTriggerFor]="actionsMenu" ><mat-icon svgIcon="settings" class="size-22"></mat-icon></button>
                <mat-menu #actionsMenu>
                    <div mat-menu-item (click)="setDefaultLocalization(localization)" *ngIf="currentUser.hasPermission('settings.update')" trans>Set as Default</div>
                    <div mat-menu-item (click)="showCrupdateLocalizationModal(localization)" *ngIf="localization.name !== 'English' && currentUser.hasPermission('localizations.update')" trans>Update</div>
                    <div mat-menu-item (click)="confirmLocalizationDeletion(localization)" *ngIf="localization.name !== 'English' && currentUser.hasPermission('localizations.delete')" trans>Delete</div>
                </mat-menu>
            </div>
        </div>

        <div *ngIf="!selectedLocalization && loading$ | async">
            <div class="nav-item skeleton-nav-item" *ngFor="let i of [1, 2, 3, 4, 5]">
                <skeleton variant="text"></skeleton>
            </div>
        </div>
    </ng-template>

    <div class="update-translations">
        <button mat-raised-button color="accent" (click)="updateLocalization()" [disabled]="loading$ | async" *ngIf="currentUser.hasPermission('localizations.update')" trans>Update</button>
    </div>
</aside>

<div class="datatable responsive-table">
    <div class="datatable-header localizations-datatable-header">
        <button mat-raised-button class="new-translation-button" color="accent" (click)="addLine()" trans>Add New Translation</button>
        <div class="input-container search-input-container">
            <input type="text" class="search" placeholder="Search Translations" trans-placeholder [formControl]="searchQuery">
            <mat-icon svgIcon="search"></mat-icon>
        </div>
    </div>
    <table class="material-table">
        <thead>
        <tr>
            <th class="source-column" trans>Source Text</th>
            <th trans>Translation</th>
            <th></th>
        </tr>
        </thead>
        <tbody>

       <ng-container *ngIf="loadingLines$ | async">
           <tr *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]">
               <td><skeleton variant="text" class="table-skeleton"></skeleton></td>
               <td><skeleton variant="text" class="table-skeleton"></skeleton></td>
           </tr>
       </ng-container>

        <tr *ngFor="let line of lines$ | async" [@fadeIn]>
            <td class="mobile-no-label source-column">{{line.key}}</td>
            <td class="mobile-no-label">
                <input type="text" class="translation-input" id="{{line.key}}" [(ngModel)]="selectedLocalization.lines[line.key]">
            </td>
            <td class="edit-column">
                <button type="button" mat-icon-button (click)="removeLine(line)" [matTooltip]="'Remove' | trans">
                    <mat-icon svgIcon="close"></mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
