
<ng-template #navItems>

</ng-template>

<ng-container *ngIf="breakpoints.isMobile$ | async; else desktopNav">
    <button [matMenuTriggerFor]="navMenu" class="no-style admin-pill-nav-mobile-selector">
        <span class="name" trans>{{activePanel$ | async}}</span>
        <mat-icon svgIcon="keyboard-arrow-down"></mat-icon>
    </button>
    <mat-menu #navMenu="matMenu" class="admin-pill-nav-menu">
        <ng-container *ngTemplateOutlet="navItems"></ng-container>
    </mat-menu>
</ng-container>

<ng-template #desktopNav>
    <aside class="admin-pill-nav rounded" style="display: none;">
        <ng-container *ngTemplateOutlet="navItems"></ng-container>
    </aside>
</ng-template>

<div class="settings-panels-container">
    <router-outlet></router-outlet>
</div>
