import {NgModule} from '@angular/core';
import {FullPlanNameComponent} from './full-plan-name.component';

@NgModule({
    declarations: [
        FullPlanNameComponent,
    ],
    exports: [
        FullPlanNameComponent,
    ]
})
export class FullPlanNameModule {}
