import { Component, OnInit, ChangeDetectionStrategy } from '@angular/core';

@Component({
  selector: 'term-of-services',
  templateUrl: './term-of-services.component.html',
  styleUrls: ['./term-of-services.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TermOfServicesComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
