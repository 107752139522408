<section *ngIf="allPlans?.length > 0">
    <div class="container-fluid py-5 compare-planes-wrapper" trans>
        <h1 class="text-center" trans>Compare Plans</h1>
        <!-- <p class="text-center" trans>Affordable plans for unlimited downloads of the latest audio.</p> -->
        <div class="row justify-content-center mt-5">
            <div class="col-lg-10 col-md-12 desktop-view-table">

                <div class="row text-center table-headings">
                    <div class="col-md-4 col-4">
                        <h4 trans>Best value</h4>
                    </div>
                    <div class="col-md-4 col-4">
                        <h4 trans>Most popular</h4>
                    </div>
                    <div class="col-md-4 col-4">
                        <h4 trans>Standard</h4>
                    </div>
                </div>
                <div class="custom-border">

                    <div class="border-class">
                        <div class="row text-center monthly-plans">
                            <div class="col-md-4 p-0 py-5" [ngClass]="planIndex==1?'highlights-plans':''"
                                *ngFor="let plan of allPlans; let planIndex = index;">
                                <h6>{{plan.name}}</h6>
                                <p class="pb-3" trans>{{plan.interval_count}} {{plan.interval}} access</p>
                                <ng-container *ngIf="plan?.features.length > 0">
                                    <ng-container *ngFor="let feature of plan?.features; let last = last;">
                                        <p class="p-3 charges-off mb-0">{{feature}}
                                        </p>
                                        <ng-container *ngIf="last && plan?.features.length < maximumFeaturesCount">
                                            <p *ngFor="let item of [].constructor(maximumFeaturesCount - plan?.features.length)"
                                                class="p-3 charges-off mb-0 ">-
                                                <!-- <i class="bi bi-x-lg text-danger"></i> -->
                                            </p>
                                        </ng-container>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngIf="plan?.features.length == 0">
                                    <p *ngFor="let item of [].constructor(maximumFeaturesCount)"
                                        class="p-3 charges-off mb-0">-
                                        <!-- <i class="bi bi-x-lg text-danger"></i> -->
                                    </p>
                                </ng-container>
                                <div class="border-bottom-0 p-4">
                                    <p>
                                        <span class="charges h5 me-2"> {{(plan.interval_count==12)?'SAVE 25% ($90)':(plan.interval_count==3)?'SAVE 20% ($18)':''}}</span>
                                    </p>
                                    <p>
                                        <span class="charges me-1">{{plan.currency_symbol}}{{plan.amount.toFixed(2)}}</span><span class="month text-capitalize" trans>/{{plan.interval}}</span>
                                    </p>
                                    <a href="javascript:;" routerLink="/web/billing/upgrade" class="btn btn-success mb-2 mt-2 subscribe-button" trans>
                                        Subscribe Now
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<!-- mobile-view-table -->
<section>

    <div class="container mobile-responsive-table">
        <div class="mobile-table text-center">
            <div class="row">
                <div class="col-md-12 mb-5" [ngClass]="planIndex==1?'bg-mobile-table':''"
                *ngFor="let plan of allPlans; let planIndex = index;">
                    <h3 *ngIf="planIndex===0" class="mb-4" trans>Best value</h3>
                    <h3 *ngIf="planIndex===1" class="mb-4 mt-5" trans>Most popular</h3>
                    <h3 *ngIf="planIndex===2" class="mb-4" trans>Standard</h3>
                    <div class="mobile-table-border">
                        <div class="annual-border my-3">
                            <h3 trans>{{plan.name}} </h3>
                            <p trans>{{plan.interval_count}} {{plan.interval}} access</p>
                        </div>
                        <div class="annual-border py-3" *ngFor="let feature of plan?.features; let last = last;">
                            <h4 trans>{{feature}}</h4>
                        </div>

                        <div class="py-3 mt-2">
                            <h4 trans>{{plan.currency_symbol}}{{plan.amount.toFixed(2)}}<span class="monthly-plan">/{{plan.interval}}</span></h4>
                            <a href="javascript:;" routerLink="/web/billing/upgrade" class="btn btn-success  mb-2 mt-2 subscribe-button" trans>
                                Subscribe Now
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>
