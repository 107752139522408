<section>
    <div class="Curated-Playlists-wrapper">
        <div class="container-fluid">
            <div class="row align-items-center h-100" trans>
                <div class="col-md-4">
                    <h1 trans>Curated Playlists</h1>
                    <p class=" my-5" trans>We have the most complete library available containing more than 60 genres
                        to browse through.</p>
                    <!-- <a class="btn btn-outline-success get-started" href="#">Get Started</a> -->
                </div>

                <div class="col-md-8">
                    <div class="background-box-shadow"></div>
                    <img src="assets/img/playlists-banner.png" class="img-fluid w-100">
                </div>
            </div>
        </div>
    </div>
</section>
