<section>
    <div class="library-section">
        <div class="gradient-section">
            <div class="container-fluid h-100" trans>
                <div class="row align-items-center h-100 m-0">
                    <div class="col-md-8">
                        <img src="assets/img/library-img.png" class="img-fluid w-100">
                    </div>

                    <div class="col-md-4">
                        <h1 class="text-white" trans>Extensive Library</h1>
                        <p class="custom-gray my-5" trans>We have the most complete library available containing more than 60
                            genres
                            to browse through.</p>
                        <!-- <a class="btn btn-outline-success  text-white get-started" href="#">Get Started</a> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
