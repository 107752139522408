<section *ngIf="carouselData">
  <!-- <div class="container"> -->
  <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
    <div class="carousel-inner">
      <div *ngFor="let caroImg of carouselData; let activeCheck = first;" [class.active]="activeCheck"
        class="carousel-item">
        <img src="assets/img/carousel.jpg" class="d-inline img-fluid" alt="...">
      </div>
    </div>
    <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
      <span class="carousel-control-prev-icon" aria-hidden="true"></span>
      <span class="visually-hidden" trans>Previous</span>
    </button>
    <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
      <span class="carousel-control-next-icon" aria-hidden="true"></span>
      <span class="visually-hidden" trans>Next</span>
    </button>
  </div>
  <!-- </div> -->
</section>

<ng-template [ngIf]="datatable.paginator.noResults$ | async" [ngIfElse]="notEmptyList">
  <div class="no-results-message py-4">
    <mat-icon class="icon" svgIcon="audiotrack"></mat-icon>
    <div class="text">
      <div class="main" trans>Nothing to display.</div>
      <div class="secondary" trans>Tracks does not found yet!</div>
    </div>
  </div>
</ng-template>
<ng-template #notEmptyList>
  <div class="datatable responsive-table dashboard_table_data">
    <!-- <datatable-header pluralResourceName="title" [showSelectedActions]="false">
  </datatable-header> -->
    <table class="material-table">
      <thead>
        <tr class="customHeader">
          <!-- <th table-header-checkbox></th> -->
          <th (click)="filterby('name')" table-sort-header="">Title</th>
          <th (click)="filterby('artists.name')" table-sort-header="" trans>Artist</th>
          <th (click)="filterby('genres.name')" table-sort-header="" trans>Genre</th>
          <th (click)="filterby('bpm')" table-sort-header="" trans>BPM</th>
          <th (click)="filterby('key')" table-sort-header="" trans>Key</th>
          <th (click)="filterby('created_at')" table-sort-header="" trans>Date</th>
        </tr>
      </thead>
      <tbody class="material-table" cdkDropList [cdkDropListDisabled]="!reorderEnabled"
        (cdkDropListDropped)="orderChanged.emit($event)">
        <ng-container *ngFor="let gaana of gaane; let _index = index">
          <tr [class.selected]="selectedTracks.has(gaana)" [attr.data-id]="gaana.id"
            [class.playing]="trackIsPlaying(gaana)" class="track-list-row" cdkDrag>
            <td><a class="d-flex align-items-center"
                [ngClass]="isDownloaded(1, gaana.user_downloads)?'text-download':''"
                style="max-width: 200px;" trans>
                <!-- <i class="bi bi-play-circle-fill play-circle-icon"></i> -->
                <playback-control-button class="small me-3" [track]="gaana" (play)="playTrack(gaana,_index)"
                  (pause)="player.pause()"></playback-control-button>
                {{gaana?.name}}
              </a></td>
            <td data-label="First Name" class="text-truncate" style="max-width: 200px;" trans>
              {{gaana?.artists && (gaana?.artists.length>0)?gaana?.artists[0]?.name : '-'}}</td>
            <td data-label="Last Name" trans>{{gaana?.genres && (gaana?.genres.length>0)?gaana?.genres[0]?.name : '-'}}</td>
            <td data-label="Last Name" trans>{{gaana?.bpm || '-'}}</td>
            <td data-label="Last Name" trans>{{gaana?.t_key || '-'}}</td>
            <td data-label="Assigned At" trans>{{gaana?.date | date:"MM/dd/yyyy"}}</td>
            <td>
              <a data-bs-toggle="collapse" id="songCollapse{{_index}}" href="#collapseDownload{{_index}}" (click)="expandRow(_index)">
                <i *ngIf="!urls.expandedIndex.includes(_index)" class="bi bi-three-dots collapse-icon"></i>
                <i *ngIf="urls.expandedIndex.includes(_index)" class="bi bi-three-dots-vertical collapse-icon"></i>
              </a>
            </td>
          </tr>
          <tr class="--custom-height-unset">
            <td colspan="7" class="p-0">
              <div class="collapse" id="collapseDownload{{_index}}">
                <table class="table modal-details mt-3">
                  <tr class="text-white downloaded-songs-list">
                    <td></td>
                    <td class="downloaded-song" trans>{{gaana?.name}}</td>
                    <td trans>
                      <i [ngClass]="isDownloaded(1, gaana.user_downloads)"
                        class="bi bi-circle-fill song-download-circle"></i>
                      <i [ngClass]="isDownloaded(2, gaana.user_downloads)"
                        class="bi bi-circle-fill song-download-circle"></i>
                      <i [ngClass]="isDownloaded(3, gaana.user_downloads)"
                        class="bi bi-circle-fill song-download-circle"></i>
                    </td>
                    <td>
                      <a (click)="downloadTrack(gaana?.id, _index)" href="javascript:void(0)"><i
                          class="bi bi-download song-download-icon"></i></a>
                    </td>
                  </tr>
                  <ng-container *ngIf="gaana?.track_versions.length > 0">
                    <tr *ngFor="let version of gaana?.track_versions; let verIndex = index;"
                      class="text-white border-top-0 downloaded-songs-list"
                      [class.selected]="selectedTracks.has(version)" [attr.data-id]="version.id"
                      [class.playing]="trackIsPlaying(version)" cdkDrag>
                      <td trans [ngClass]="isVerDownloaded(1, version?.download_count)?'text-download':'text-truncate'">
                        <playback-control-button class="small me-3" [track]="version"
                          (play)="playVersionTrack(gaana?.track_versions,version,verIndex)" (pause)="player.pause()">
                        </playback-control-button>
                      </td>
                      <td trans [ngClass]="isVerDownloaded(1, version?.download_count)?'text-download':'text-truncate'">
                        {{version?.name}}</td>
                      <td>
                        <i [ngClass]="isVerDownloaded(1, version?.download_count)"
                          class="bi bi-circle-fill song-download-circle"></i>
                        <i [ngClass]="isVerDownloaded(2, version?.download_count)"
                          class="bi bi-circle-fill song-download-circle"></i>
                        <i [ngClass]="isVerDownloaded(3, version?.download_count)"
                          class="bi bi-circle-fill song-download-circle"></i>
                      </td>
                      <td>
                        <a (click)="downloadTrackVersion(gaana?.id, version?.id, _index, verIndex)"><i
                            class="bi bi-download song-download-icon"></i></a>
                      </td>
                    </tr>
                  </ng-container>
                  <!-- <tr class="border-top-0 ">
                <td> Do It To It - Kwest vs Acraze Edit (Dirty)</td>
                <td><i class="bi bi-circle song-download-circle"></i> <i
                    class="bi bi-circle song-download-circle"></i> <i class="bi bi-circle song-download-circle"></i>
                </td>
                <td><a href="javascript:void(0)"><i class="bi bi-download song-download-icon"></i></a></td>
              </tr> -->
                </table>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>

  </div>
  <datatable-footer></datatable-footer>
</ng-template>

<loading-indicator class="overlay" [isVisible]="loading$ | async"></loading-indicator>