<section>
  <div class="input-group my-5">
    <input type="text" class="form-control search-bar text-white" (change)='getsearchcus($event)'
      placeholder="Search..." trans-placeholder aria-label="Recipient's username" aria-describedby="basic-addon2"
      value="{{catdata}}">
    <span class="input-group-text" id="basic-addon2">
      <i class="bi bi-search"></i>
    </span>
  </div>
  <!-- <input [formControl]="searchPanel.searchCategory" type="hidden"> -->

  <div class="d-flex align-items-center mb-3 justify-content-between">
    <!-- <p class="search-category-song mb-0 w-50" trans>Search by</p> -->
    <div class="d-flex align-items-center justify-content-end w-30">
      <div class="sort show-flters bg-muted text-center d-block d-lg-none text-nowrap heading-color me-3"><span
          class="text-drukWide-medium d-flex align-items-center "><i class="bi bi-filter"></i><span class="d-inline-block d-md-none me-1">View</span>
          Filters</span></div>

      <div class="tranding_list_icon ms-2 show-flters-2">
        <!-- <img src="assets/img/hot-brands.svg"> -->
       
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
          <!--! Font Awesome Pro 6.1.1 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2022 Fonticons, Inc. -->
          <path
            d="M414.9 161.5C340.2 29 121.1 0 121.1 0S222.2 110.4 93 197.7C11.3 252.8-21 324.4 14 402.6c26.8 59.9 83.5 84.3 144.6 93.4-29.2-55.1-6.6-122.4-4.1-129.6 57.1 86.4 165 0 110.8-93.9 71 15.4 81.6 138.6 27.1 215.5 80.5-25.3 134.1-88.9 148.8-145.6 15.5-59.3 3.7-127.9-26.3-180.9z" />
        </svg>
        <p class="mb-0 ps-2 fs-3">Trending List</p>

      </div>
    </div>

  </div>

  <form class="hide-filters">
    <div class="d-flex align-items-center mb-3 justify-content-between">
      <p class="search-category-song mb-0" trans>Search by</p>
      <span class="close_searchby_filter"><i class="bi bi-x-lg"></i></span>
    </div>

    <select (change)='getGenre($event)' [formControl]="searchcat" class="fw-bold form-select mb-2 category-selector">
      <option selected value="0" trans>Category</option>
      <option *ngFor="let cates of allCategories" value="{{cates.id}}">{{cates.name}}</option>
    </select>

    <select class="fw-bold  form-select form-select-lg mb-2 category-selector" [formControl]="searchgen"
      (change)='getGenresearch($event)'>
      <option value="0" trans>Genre</option>
      <option *ngFor="let genre of allGenres" value="{{genre.id}}">{{genre.name}}</option>
    </select>

    <select class="fw-bold  form-select form-select-lg category-selector" [formControl]="searchsubgenre"
      (change)='getsubGenresearch($event)'>
      <option value="0" trans>Sub-Genre</option>
      <option *ngFor="let subGenre of allSubGenres" value="{{subGenre.id}}">{{subGenre.name}}</option>
    </select>
  </form>
</section>

<section>
  <div class="hide-filters-2">
    <div class="d-flex align-items-center justify-content-between mb-3">
      <h2 class="mb-0 tranding-list-heading" trans>Trending List</h2>
      <span class="close_trending_filter"><i class="bi bi-x-lg"></i></span>
    </div>
    <track-list [showTrendingList]="'true'" [dataSourceTrending]="datatable" [queueItemId]="queueId()">
    </track-list>
  </div>
</section>