<section>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-8">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="support-heading " trans>Support</h1>
                        <div class="accordion-wrapper">
                            <div class="accordion accordion-flush" id="accordionFlushExample">
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingOne">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseOne"
                                            aria-expanded="false" aria-controls="flush-collapseOne" trans>
                                            How to contact us

                                        </button>
                                    </h2>
                                    <div id="flush-collapseOne" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingOne" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>
                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>
                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingTwo">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseTwo"
                                            aria-expanded="false" aria-controls="flush-collapseTwo" trans>
                                            What is DJ Mixinglab?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseTwo" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingTwo" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingThree">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseThree"
                                            aria-expanded="false" aria-controls="flush-collapseThree" trans>
                                            Why join DJ Mixinglab?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseThree" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingThree" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingfour">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapsefour"
                                            aria-expanded="false" aria-controls="flush-collapsefour" trans>
                                            Can I preview your record pool before purchasing?
                                        </button>
                                    </h2>
                                    <div id="flush-collapsefour" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingfour" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingfive">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapsefive"
                                            aria-expanded="false" aria-controls="flush-collapsefive" trans>
                                            Questionnaire requirement
                                        </button>
                                    </h2>
                                    <div id="flush-collapsefive" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingfive" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingsix">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapsesix"
                                            aria-expanded="false" aria-controls="flush-collapsesix" trans>
                                            What are the quality and formats available for audio?
                                        </button>
                                    </h2>
                                    <div id="flush-collapsesix" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingsix" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingseven">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseseven"
                                            aria-expanded="false" aria-controls="flush-collapseseven" trans>
                                            How do I change my password?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseseven" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingseven" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingeight">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseeight"
                                            aria-expanded="false" aria-controls="flush-collapseeight" trans>
                                            How to use your crate
                                        </button>
                                    </h2>
                                    <div id="flush-collapseeight" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingeight" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans> 
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingnine">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapsenine"
                                            aria-expanded="false" aria-controls="flush-collapsenine" trans>
                                            I can't log in via Facebook. What should I do?
                                        </button>
                                    </h2>
                                    <div id="flush-collapsenine" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingnine" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingten">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseten"
                                            aria-expanded="false" aria-controls="flush-collapseten" trans>
                                            Removal of Information
                                        </button>
                                    </h2>
                                    <div id="flush-collapseten" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingten" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>


                                <div class="accordion-item">
                                    <h2 class="accordion-header" id="flush-headingeleven">
                                        <button class="accordion-button collapsed" type="button"
                                            data-bs-toggle="collapse" data-bs-target="#flush-collapseeleven"
                                            aria-expanded="false" aria-controls="flush-collapseeleven" trans>
                                            Can I filter a song version?
                                        </button>
                                    </h2>
                                    <div id="flush-collapseeleven" class="accordion-collapse collapse"
                                        aria-labelledby="flush-headingeleven" data-bs-parent="#accordionFlushExample">
                                        <div class="accordion-body">
                                            <p trans>
                                                For any questions, comments, or concerns, you can reach us via the chat
                                                button on the bottom right-hand corner, submit a contact form or send an
                                                email to info@bpmsupreme.com.</p>

                                            <p class="my-5" trans> *For any issues regarding, send an email to
                                                info@djmixinglabissue.com
                                                *For inquiries regarding, send an email to info@djmixinglabinqury.com
                                            </p>

                                            <p class="mb-0" trans>Business hours (Pacific Standard Time): </p>
                                            <p class="mb-0" trans>Monday - Thursday (9AM - 6PM)</p>
                                            <p trans>Friday (8AM - 5PM)</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="Generate-ticket d-flex justify-content-end">
                <a routerLink="/web/support" class="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal" trans>
                    <i class="bi bi-question-circle-fill"></i>
                    Ticket Generate
                </a>
            </div>
            <div class="ticket-generate-form">
                <!-- Modal -->
                <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel"
                    aria-hidden="true">
                    <div class="modal-dialog ticket-generate-pop-up">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5 class="modal-title" id="exampleModalLabel" trans>Ticket Generate</h5>
                                <button #closeModal type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"> <i
                                        class="bi bi-x-lg modal-close-icon"></i></button>
                            </div>
                            <div class="modal-body">
                                <form [formGroup]="ticketForm" (ngSubmit)="generateTicket()">
                                    <div class="row">
                                        <div class="col-md-6 my-3">
                                            <label class="form-label" trans>Name*</label>
                                            <input type="text" class="form-control user-name"
                                                formControlName="name" placeholder="Enter name" trans-placeholder
                                                aria-label="name">
                                            <div *ngIf="ticketForm.controls['name'].invalid && (ticketForm.controls['name'].dirty || ticketForm.controls['name'].touched)"
                                                class="error big-error">
                                                <div *ngIf="ticketForm.controls['name'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Name is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 my-3">
                                            <label for="exampleInputEmail1" class="form-label" trans>Email*</label>
                                            <input type="email" class="form-control user-name" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter email" formControlName="email" trans-placeholder>
                                            <div *ngIf="ticketForm.controls['email'].invalid && (ticketForm.controls['email'].dirty || ticketForm.controls['email'].touched)"
                                                class="error big-error">
                                                <div *ngIf="ticketForm.controls['email'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Email is required!
                                                </div>
                                                <div *ngIf="ticketForm.controls['email'].errors?.pattern" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Invalid email!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 my-3">
                                            <label for="Phonenumber" class="form-label" trans>Phone*</label>
                                            <input type="number" class="form-control user-name" id="Phonenumber"
                                                formControlName="phone" placeholder="Enter phone number" trans-placeholder>
                                            <div *ngIf="ticketForm.controls['phone'].invalid && (ticketForm.controls['phone'].dirty || ticketForm.controls['phone'].touched)"
                                                class="error big-error">
                                                <div *ngIf="ticketForm.controls['phone'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Phone is required!
                                                </div>
                                                <div *ngIf="ticketForm.controls['phone'].errors.maxlength" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon>
                                                    Maximum length should be 10 digits!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 my-3">
                                            <label trans>Reason</label>
                                            <select class="form-select form-control mt-2 user-name" formControlName="reason_id"
                                                aria-label="Default select example">
                                                <option value="" selected disabled trans>select reason</option>
                                                <ng-container *ngFor="let reason of ticketReasons; let first = first">
                                                    <option value="{{reason.id}}">{{reason.title}}</option>
                                                </ng-container>
                                            </select>
                                            <div *ngIf="ticketForm.controls['reason_id'].invalid && (ticketForm.controls['reason_id'].dirty || ticketForm.controls['reason_id'].touched)"
                                                class="error big-error">
                                                <div *ngIf="ticketForm.controls['reason_id'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Reason is required!
                                                </div>
                                            </div>
                                        </div>

                                        <div class="col-md-12 my-3">
                                            <label for="exampleFormControlTextarea1" class="form-label" trans>Message</label>
                                            <textarea class="form-control user-name" id="exampleFormControlTextarea1"
                                                rows="3" placeholder="Enter message" formControlName="message" trans-placeholder></textarea>
                                            <div *ngIf="ticketForm.controls['message'].invalid && (ticketForm.controls['message'].dirty || ticketForm.controls['message'].touched)"
                                                class="error big-error">
                                                <div *ngIf="ticketForm.controls['message'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Message is required!
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            <button type="submit" class="btn btn-success mt-3" [disabled]="!ticketForm.valid" trans>Submit</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>


    </div>
</section>