<div class="datatable responsive-table">
    <datatable-header pluralResourceName="channels">
        <ng-container leftContent>
            <a mat-raised-button color="accent" routerLink="/admin/channels/new" trans>Add</a>
        </ng-container>
        <button rightContent mat-raised-button color="accent" (click)="maybeDeleteSelectedChannels()" trans>Delete Selected</button>
    </datatable-header>
    <table class="material-table">
        <thead>
        <tr>
            <th table-header-checkbox></th>
            <th table-sort-header trans>Name</th>
            <th table-sort-header trans>Content Type</th>
            <th table-sort-header trans>Layout</th>
            <th table-sort-header trans>Auto Update</th>
            <th table-sort-header="updated_at" trans>Last Updated</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let channel of channels$ | async">
            <td [table-body-checkbox]="channel.id"></td>
            <td>{{channel.name}}</td>
            <td data-label="Content Type">{{channel.content_type}}</td>
            <td data-label="Layout">{{channel.layout}}</td>
            <td class="hidden-on-mobile">{{channel.auto_update ? channel.auto_update : '-'}}</td>
            <td class="hidden-on-mobile">{{channel.updated_at | formattedDate}}</td>
            <td class="edit-column">
                <a mat-icon-button [routerLink]="['/admin/channels', channel.id]" [matTooltip]="'Edit channel' | trans">
                    <mat-icon svgIcon="edit"></mat-icon>
                </a>
            </td>
        </tr>
        </tbody>
    </table>
    <no-results-message svgImage="playlist.svg" *ngIf="datatable.paginator.noResults$ | async">
        <span primary-text>No channels have been created yet</span>
    </no-results-message>
    <datatable-footer></datatable-footer>
</div>
