<div class="media-image-container" [class.show-album-border]="!isTrack(media)">
    <media-image [media]="media"></media-image>
    <img class="album-border-image" [src]="settings.getAssetUrl('images/album-border.png')" alt="album border image">
</div>
<div class="middle-column">
    <div class="artist-name">{{isTrack(media) ? media.artists[0].name : media.artist.name}}</div>
    <div class="track-name">{{media.name}}</div>
    <div class="genre-row">
        <mat-chip class="track-genre" *ngIf="media.genres.length">{{media.genres[0].name}}</mat-chip>
    </div>
    <div class="message">
        <span trans>Upload complete.</span> <a [routerLink]="mediaLink()" trans> View now.</a>
    </div>
</div>
<div class="right-column">
    <div class="title" trans>Share your new {{isTrack(media) ? 'track' : 'album' }}</div>
    <div class="share-icons">
        <button mat-icon-button (click)="shareUsing('facebook')">
            <img src="{{settings.getAssetUrl('images/social-icons/facebook.png')}}" alt="facebook-icon">
        </button>
        <button mat-icon-button (click)="shareUsing('twitter')">
            <img src="{{settings.getAssetUrl('images/social-icons/twitter.png')}}" alt="twitter-icon">
        </button>
        <button mat-icon-button (click)="shareUsing('tumblr')">
            <img src="{{settings.getAssetUrl('images/social-icons/tumblr.png')}}" alt="tumblr-icon">
        </button>
        <button mat-icon-button (click)="shareUsing('blogger')">
            <img src="{{settings.getAssetUrl('images/social-icons/bloggr.png')}}" alt="blogger-icon">
        </button>
    </div>
    <div class="track-link-input input-container">
        <label for="media-item-link" class="hidden" trans>Media link</label>
        <input #trackLinkInput id="media-item-link" type="text" readonly [value]="mediaUrl()" (click)="$event.target.select()">
    </div>
</div>
