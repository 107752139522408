<auth-page>
    <form (ngSubmit)="resetPassword()" *ngIf="errors$ | async as errors" [formGroup]="form" ngNativeValidate>
        <div class="header" trans>Reset your account password</div>

        <div class="many-inputs">
            <div class="input-container">
                <label for="email" trans>Email</label>
                <input type="email" formControlName="email" id="email" required>
                <p class="error email-error" *ngIf="errors.email">{{errors.email}}</p>
                <div *ngIf="form.controls['email'].invalid && (form.controls['email'].dirty || form.controls['email'].touched)"
                    class="error big-error">
                    <div *ngIf="form.controls['email'].errors.required" trans>
                        <mat-icon svgIcon="warning"></mat-icon> Email is required!
                    </div>
                    <div *ngIf="form.controls['email'].errors?.pattern" trans>
                        <mat-icon svgIcon="warning"></mat-icon> Invalid email!
                    </div>
                </div>
            </div>

            <div class="input-container">
                <label for="password" trans>New Password</label>
                <input type="password" formControlName="password" id="password" required>
                <p class="error password-error" *ngIf="errors.password">{{errors.password}}</p>
                <div *ngIf="form.controls['password'].invalid && (form.controls['password'].dirty || form.controls['password'].touched)"
                    class="error big-error">
                    <div *ngIf="form.controls['password'].errors.required" trans>
                        <mat-icon svgIcon="warning"></mat-icon> Password is required!
                    </div>
                    <div *ngIf="form.controls['password'].errors.minlength" trans>
                        <mat-icon svgIcon="warning"></mat-icon>
                        Password must be at least 6 characters
                        </div>
                </div>
            </div>

            <div class="input-container">
                <label for="confirm_password" trans>Confirm New Password</label>
                <input type="password" formControlName="confirm_password" name="confirm_password"
                    id="confirm_password" required>
                <div *ngIf="form.controls['confirm_password'].invalid && (form.controls['confirm_password'].dirty || form.controls['confirm_password'].touched)"
                    class="error big-error">
                    <div *ngIf="form.controls['confirm_password'].errors.required" trans>
                        <mat-icon svgIcon="warning"></mat-icon>Confirm password is required!
                    </div>
                </div>
                <div *ngIf="form?.errors?.notSame" class="error big-error" trans>
                    <mat-icon svgIcon="warning"></mat-icon>Confirm password does not match!
                </div>
            </div>
        </div>

        <button mat-raised-button class="primary-button" color="accent" type="submit" [disabled]="!form.valid || (loading$ | async)"
            trans>Reset Password</button>
    </form>
</auth-page>
