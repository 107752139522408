<section>
    <div class="container-fluid">
        <div class="complete-profile-wrapper">
            <div class="row">
                <h1 class="" trans>Account</h1>
                <ul class="nav nav-tabs mb-5 account-tabs" id="myTab" role="tablist">
                    <li class="nav-item" role="presentation">
                        <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home"
                            type="button" role="tab" aria-controls="home" aria-selected="true" trans>Profile</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile"
                            type="button" role="tab" aria-controls="profile" aria-selected="false"
                            trans>Membership</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact"
                            type="button" role="tab" aria-controls="contact" aria-selected="false" trans>Payment
                            History</button>
                    </li>
                    <li class="nav-item" role="presentation">
                        <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact1"
                            type="button" role="tab" aria-controls="contact1" aria-selected="false" trans>Download
                            History</button>
                    </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                    <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <div class="complete-profile-border mb-5">
                            <div class="col-md-12">
                                <h3 class="profile-details" trans>Profile details</h3>
                                <!-- <hr> -->
                                <form [formGroup]="profileForm" (ngSubmit)="save()">
                                    <div class="row mt-5">
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>First Name*</label>
                                            <input type="text" class="form-control user-name"
                                                formControlName="first_name" placeholder="First Name" trans-placeholder
                                                aria-label="First name">
                                            <div *ngIf="profileForm.controls['first_name'].invalid && (profileForm.controls['first_name'].dirty || profileForm.controls['first_name'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['first_name'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> First Name is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Last Name*</label>
                                            <input type="text" class="form-control user-name"
                                                formControlName="last_name" placeholder="Last Name" trans-placeholder
                                                aria-label="Last name">
                                            <div *ngIf="profileForm.controls['last_name'].invalid && (profileForm.controls['last_name'].dirty || profileForm.controls['last_name'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['last_name'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Last Name is required!
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Username*</label>
                                            <input type="text" (keydown.space)="$event.preventDefault();"
                                                class="form-control user-name" formControlName="user_name"
                                                placeholder="Enter username" trans-placeholder aria-label="Username">
                                            <div *ngIf="profileForm.controls['user_name'].invalid && (profileForm.controls['user_name'].dirty || profileForm.controls['user_name'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['user_name'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> User Name is required!
                                                </div>
                                                <div *ngIf="profileForm.controls['user_name'].errors.minlength" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Please type atleast 5
                                                    characters!
                                                </div>
                                                <div *ngIf="profileForm.controls['user_name'].errors.maxlength" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Only 25 characters are
                                                    allowed!
                                                </div>
                                                <div *ngIf="profileForm.controls['user_name'].errors.pattern" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> No special characters are
                                                    allowed!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Company</label>
                                            <input type="text" class="form-control user-name" formControlName="company"
                                                placeholder="Enter company name" aria-label="Company" trans-placeholder>
                                        </div> -->
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Dj Name*</label>
                                            <input type="text" class="form-control user-name" formControlName="djname"
                                                trans-placeholder placeholder="Enter dj name" aria-label="Dj Name">
                                            <div *ngIf="profileForm.controls['djname'].invalid && (profileForm.controls['djname'].dirty || profileForm.controls['djname'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['djname'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Dj Name is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label for="exampleInputEmail1" class="form-label" trans>Email*</label>
                                            <input type="email" class="form-control user-name" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" formControlName="email" trans-placeholder
                                                placeholder="Enter email">
                                            <div *ngIf="profileForm.controls['email'].invalid && (profileForm.controls['email'].dirty || profileForm.controls['email'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['email'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Email is required!
                                                </div>
                                                <div *ngIf="profileForm.controls['email'].errors?.pattern" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Invalid email!
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <select class="form-control" name="country" id="country">
                                            <option value="" disabled selected>Select Country</option>
                                            <option *ngFor="let country of countriesnew" [value]="country.number">
                                                {{country.name}}-{{country.number}}</option>
                                        </select> -->
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label for="Phonenumber" id="multi-national-number" class="form-label"
                                                trans>Phone*</label><br/>
                                                <input type="text" ng2TelInput [ng2TelInputOptions] = "inputTelOptions" min="0" class="form-control user-name" id="Phonenumber"
                                                formControlName="phone" placeholder="Enter phone number" (countryChange)="countryChange($event)"
                                                trans-placeholder>
                                            <div *ngIf="profileForm.controls['phone'].invalid && (profileForm.controls['phone'].dirty || profileForm.controls['phone'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['phone'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Phone is required!
                                                </div>
                                                <div *ngIf="profileForm.controls['phone'].errors.maxlength" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon>
                                                    Maximum length should be 10 digits!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5 custom-class-dob">
                                            <label class="form-label" trans>Date of Birth</label>
                                            <input matInput [matDatepicker]="dp3" class="form-control" disabled
                                                id="date" formControlName="dob">
                                            <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                                            <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label for="djtype" class="form-label" trans>Type of Dj*</label>
                                            <select class="form-select form-control user-name" id="djtype"
                                                aria-label="Default select example" formControlName="djtype">
                                                <option value="" selected trans>---Select---</option>
                                                <option *ngFor="let item of djTypes" value="{{item.model.id}}" trans>
                                                    {{item.model.name}}</option>
                                            </select>
                                            <div *ngIf="profileForm.controls['djtype'].invalid && (profileForm.controls['djtype'].dirty || profileForm.controls['djtype'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['djtype'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon>Type of Dj is required!
                                                </div>
                                            </div>
                                        </div>
                                        <!-- <div class="input-group mb-4">
                                                <input type="password" name="password" autocomplete="current-password"
                                                    required="" id="id_password" class="form-control"
                                                    formControlName="" placeholder="Password">
                                                <span class="input-group-text"><i class="bi bi-eye-slash"
                                                        id="togglePassword"></i></span>
                                            </div> -->
                                        <div class="col-md-12 mb-4 mb-md-5">
                                            <div class="row">
                                                <label for="form-label" trans>Where Do You Regularly Perform?*</label>
                                                <div class="col-md-4" formArrayName="regular_perform_location"
                                                    *ngFor="let loc of regular_perform_location.controls;let i=index;">
                                                    <input type="text" class="form-control user-name mt-2"
                                                        [formControlName]="i" placeholder="Enter" trans-placeholder
                                                        aria-label="Username">
                                                    <div *ngIf="regular_perform_location.controls[i].invalid && (regular_perform_location.controls[i].dirty || regular_perform_location.controls[i].touched)"
                                                        class="error big-error">
                                                        <div *ngIf="getValidity(i)" trans>
                                                            <mat-icon svgIcon="warning"></mat-icon> This field is
                                                            required!
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-4 mb-md-5">
                                            <div class="col-md-6">
                                                <label class="form-label" trans>How Many People Do You Perform For
                                                    Weekly*</label>
                                                <input type="number" min="1" class="form-control user-name"
                                                    formControlName="number_of_people_perform_weekly"
                                                    placeholder="Enter" trans-placeholder aria-label="Username">
                                                <div *ngIf="profileForm.controls['number_of_people_perform_weekly'].invalid && (profileForm.controls['number_of_people_perform_weekly'].dirty || profileForm.controls['number_of_people_perform_weekly'].touched)"
                                                    class="error big-error">
                                                    <div *ngIf="profileForm.controls['number_of_people_perform_weekly'].errors.required"
                                                        trans>
                                                        <mat-icon svgIcon="warning"></mat-icon>
                                                        This field is required!
                                                    </div>
                                                    <div *ngIf="profileForm.controls['number_of_people_perform_weekly'].errors.max"
                                                        trans>
                                                        <mat-icon svgIcon="warning"></mat-icon>
                                                        Maximum number of people is 6!
                                                    </div>
                                                    <div *ngIf="profileForm.controls['number_of_people_perform_weekly'].errors.min"
                                                        trans>
                                                        <mat-icon svgIcon="warning"></mat-icon>
                                                        Minimum number of people is 0!
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Website</label>
                                            <input type="text" class="form-control user-name" formControlName="website"
                                                trans-placeholder placeholder="Enter website name"
                                                aria-label="Username">
                                            <div *ngIf="profileForm.controls['website'].invalid && (profileForm.controls['website'].dirty || profileForm.controls['website'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['website'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Website is required!
                                                </div>
                                                <div *ngIf="profileForm.controls['website'].errors.pattern" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Please enter a valid url!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Instagram</label>
                                            <input type="text" class="form-control user-name" trans-placeholder
                                                formControlName="instagram" placeholder="Enter instagram link"
                                                aria-label="Username">
                                            <div *ngIf="profileForm.controls['instagram'].invalid && (profileForm.controls['instagram'].dirty || profileForm.controls['instagram'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['instagram'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Instagram is required!
                                                </div>
                                                <div *ngIf="profileForm.controls['instagram'].errors.pattern" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Please enter a valid url!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Facebook</label>
                                            <input type="text" class="form-control user-name" formControlName="facebook"
                                                trans-placeholder placeholder="Enter facebook link"
                                                aria-label="Username">
                                            <div *ngIf="profileForm.controls['facebook'].invalid && (profileForm.controls['facebook'].dirty || profileForm.controls['facebook'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['facebook'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Facebook is required!
                                                </div>
                                                <div *ngIf="profileForm.controls['facebook'].errors.pattern" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Please enter a valid url!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>Twitter</label>
                                            <input type="text" class="form-control user-name" formControlName="twitter"
                                                placeholder="Enter twitter link" aria-label="Username"
                                                trans-placeholder>
                                            <div *ngIf="profileForm.controls['twitter'].invalid && (profileForm.controls['twitter'].dirty || profileForm.controls['twitter'].touched)"
                                                class="error big-error">
                                                <div *ngIf="profileForm.controls['twitter'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Twitter is required!
                                                </div>
                                                <div *ngIf="profileForm.controls['twitter'].errors.pattern" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Please enter a valid url!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-details-save">
                                            <!-- <a class="btn login-button mb-4" href="javascript:void(0)"
                                                    routerLink="/plans">Save</a> -->
                                            <button type="submit" class="btn btn-success login-button"
                                                [disabled]="!profileForm.valid" trans>Save
                                                Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="complete-profile-border mb-5">
                            <div class="col-md-12">
                                <h3 class="profile-details" trans>Address details</h3>
                                <!-- <hr> -->
                                <form [formGroup]="addressForm" (ngSubmit)="saveAddress()">
                                    <div class="row mt-5">
                                        <div class="col-md-12 mb-4 mb-md-5">
                                            <label class="form-label" for="form7Example4" trans>Address*</label>
                                            <input type="text" id="form7Example4" formControlName="address"
                                                trans-placeholder placeholder="Enter address"
                                                class="form-control user-name" />
                                            <div *ngIf="addressForm.controls['address'].invalid && (addressForm.controls['address'].dirty || addressForm.controls['address'].touched)"
                                                class="error big-error">
                                                <div *ngIf="addressForm.controls['address'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Address is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-12 mb-4 mb-md-5">
                                            <label class="form-label" trans>Suite / Apt</label>
                                            <input type="text" class="form-control user-name"
                                                formControlName="suite_apt" placeholder="Enter suite / apt"
                                                aria-label="Username" trans-placeholder>
                                        </div>


                                        <!-- <div class="input-group mb-3">
                                                <input type="password" name="password" autocomplete="current-password"
                                                    required="" id="id_password" class="form-control"
                                                    formControlName="" placeholder="Password">
                                                <span class="input-group-text"><i class="bi bi-eye-slash"
                                                        id="togglePassword"></i></span>
                                            </div> -->
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label class="form-label" trans>City*</label>
                                            <input type="text" class="form-control user-name" formControlName="city"
                                                placeholder="Enter city" aria-label="city" trans-placeholder>
                                            <div *ngIf="addressForm.controls['city'].invalid && (addressForm.controls['city'].dirty || addressForm.controls['city'].touched)"
                                                class="error big-error">
                                                <div *ngIf="addressForm.controls['city'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> City is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label for="zipcode" class="form-label" trans>Zip code*</label>
                                            <input type="number" min="1" class="form-control user-name" id="zipcode"
                                                formControlName="zipcode" placeholder="Enter zip code"
                                                trans-placeholder>
                                            <div *ngIf="addressForm.controls['zipcode'].invalid && (addressForm.controls['zipcode'].dirty || addressForm.controls['zipcode'].touched)"
                                                class="error big-error">
                                                <div *ngIf="addressForm.controls['zipcode'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Zip code is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label for="State" class="form-label" trans>State*</label>
                                            <select class="form-select form-control user-name" formControlName="state"
                                                aria-label="Default select example">
                                                <option value="" selected trans>
                                                    {{addressForm.get('country').value?'---Select---':'Before select
                                                    country'}}</option>
                                                <option value="{{state.name}}" *ngFor="let state of states" trans>
                                                    {{state.name}}</option>
                                            </select>
                                            <div *ngIf="addressForm.controls['state'].invalid && (addressForm.controls['state'].dirty || addressForm.controls['state'].touched)"
                                                class="error big-error">
                                                <div *ngIf="addressForm.controls['state'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> State is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-4 mb-md-5">
                                            <label for="Country" class="form-label" trans>Country*</label>
                                            <select class="form-select form-control user-name" formControlName="country"
                                                aria-label="Default select example"
                                                (change)="getStates($event.target.value)">
                                                <option value=" " selected trans>---Select---</option>
                                                <option value="{{country.name}}" *ngFor="let country of countries"
                                                    trans>{{country.name}}</option>
                                            </select>
                                            <div *ngIf="addressForm.controls['country'].invalid && (addressForm.controls['country'].dirty || addressForm.controls['country'].touched)"
                                                class="error big-error">
                                                <div *ngIf="addressForm.controls['country'].errors.required" trans>
                                                    <mat-icon svgIcon="warning"></mat-icon> Country is required!
                                                </div>
                                            </div>
                                        </div>
                                        <div class="profile-details-save">
                                            <!-- <a class="btn login-button mb-3" href="javascript:void(0)"
                                                    routerLink="/plans">Save</a> -->
                                            <button type="submit" class="btn btn-success login-button"
                                                [disabled]="!addressForm.valid" trans>Save
                                                Changes</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <!-- <div class="complete-profile-border mb-5">
                                    <div class="col-md-12">
                                        <h3 class="profile-details">Social Account</h3>
                                        <hr>
                                        <div class="media d-flex nowrap my-4 align-items-center">
                                            <div class="media-left google-icon"><i class="bi bi-google"></i></div>
                                            <div class="media-body w-100 mx-3">Google</div>
                                            <div class="media-right"><a href="#" class="btn btn-outline-success">Add</a></div>
                                        </div>
                                        <div class="media d-flex nowrap my-4 align-items-center">
                                            <div class="media-left facebook-icon"><i class="fab fa-facebook-square"></i></div>
                                            <div class="media-body w-100 mx-3">https://www.facebook.com/djmixinglab</div>
                                            <div class="media-right"><a href="#" class="btn btn-outline-success">Update</a>
                                            </div>
                                        </div>
                                        <div class="media d-flex nowrap my-4 align-items-center">
                                            <div class="media-left twitter-icon"><i class="bi bi-twitter"></i></div>
                                            <div class="media-body w-100 mx-3">Twitter</div>
                                            <div class="media-right"><a href="#" class="btn btn-outline-success">Add</a></div>
                                        </div>
                                    </div>
                                </div> -->
                        <div class="complete-profile-border mb-5">
                            <form [formGroup]="passwordForm" (ngSubmit)="savePassword()">
                                <div class="row">
                                    <h3 class="profile-details mb-5" trans>Update Password</h3>
                                    <div class="col-md-4 mb-4 mb-md-5">
                                        <label for="form-label" trans>Current Password*</label>
                                        <input type="password" class="form-control user-name mt-2"
                                            formControlName="current_password" placeholder="Enter current password"
                                            aria-label="Current Password*">
                                        <div *ngIf="passwordForm.controls['current_password'].invalid && (passwordForm.controls['current_password'].dirty || passwordForm.controls['current_password'].touched)"
                                            class="error big-error">
                                            <div *ngIf="passwordForm.controls['current_password'].errors.required"
                                                trans>
                                                <mat-icon svgIcon="warning"></mat-icon> Password is required!
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-4 mb-md-5">
                                        <label for="form-label" trans>New Password*</label>
                                        <input type="password" class="form-control user-name mt-2"
                                            formControlName="new_password" placeholder="Enter new password"
                                            aria-label="New Password*">
                                        <div *ngIf="passwordForm.controls['new_password'].invalid && (passwordForm.controls['new_password'].dirty || passwordForm.controls['new_password'].touched)"
                                            class="error big-error">
                                            <div *ngIf="passwordForm.controls['new_password'].errors.required" trans>
                                                <mat-icon svgIcon="warning"></mat-icon> New password is required!
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4 mb-4 mb-md-5">
                                        <label for="form-label" trans>Confirm New Password*</label>
                                        <input type="password" class="form-control user-name mt-2"
                                            formControlName="confirm_password" placeholder="Enter confirm new password"
                                            aria-label="Confirm New Password*" trans-placeholder>
                                        <div *ngIf="passwordForm.controls['confirm_password'].invalid && (passwordForm.controls['confirm_password'].dirty || passwordForm.controls['confirm_password'].touched)"
                                            class="error big-error">
                                            <div *ngIf="passwordForm.controls['confirm_password'].errors.required"
                                                trans>
                                                <mat-icon svgIcon="warning"></mat-icon>Confirm password is required!
                                            </div>
                                        </div>
                                        <div *ngIf="passwordForm?.errors?.notSame" class="error big-error" trans>
                                            <mat-icon svgIcon="warning"></mat-icon>Confirm password does not match!
                                        </div>
                                    </div>
                                    <!-- <div class="continue-button my-4 d-flex justify-content-end">
                                        <a href="#" class="btn login-button mt-0" [disabled]="!passwordForm.valid">Save Changes</a>
                                    </div> -->
                                    <div class="profile-details-save">
                                        <button type="submit" class="btn btn-success login-button"
                                            [disabled]="!passwordForm.valid" trans>Save Changes</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="Membership-tab">
                        <membership></membership>
                    </div>
                    <div class="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="Payment History-tab">
                        <payment></payment>
                        <!-- <table class="table my-5 payment-history">
                            <thead>
                                <tr>
                                    <th scope="col" class="p-4">Order id</th>
                                    <th scope="col" class="p-4">Date</th>
                                    <th scope="col" class="p-4">Plan</th>
                                    <th scope="col" class="p-4">Payment Menthod</th>
                                    <th scope="col" class="p-4">Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td class="p-4">#2493387</td>
                                    <td class="p-4">27/11/2021</td>
                                    <td class="p-4">Standard (Monthly)</td>
                                    <td class="p-4">Paypal</td>
                                    <td class="p-4 text-success">$30.00</td>
                                </tr>
                                <tr>
                                    <td class="p-4">#2493386</td>
                                    <td class="p-4">27/10/2021</td>
                                    <td class="p-4">Standard (Monthly)</td>
                                    <td class="p-4">Paypal</td>
                                    <td class="p-4 text-success">$30.00</td>
                                </tr>
                                <tr>
                                    <td class="p-4">#2493385</td>
                                    <td class="p-4">08/10/2020</td>
                                    <td class="p-4">Best value (Annual)</td>
                                    <td class="p-4">Paypal</td>
                                    <td class="p-4 text-success">$270.00</td>
                                </tr>
                            </tbody>
                        </table> -->
                    </div>
                    <div class="tab-pane fade" id="contact1" role="tabpanel" aria-labelledby="Download History-tab">
                        <div class="responsive-table mb-5">
                            <!-- <datatable-header pluralResourceName="title" [showSelectedActions]="false">
                            </datatable-header> -->
                            <table class="material-table">
                                <thead>
                                    <tr class="customHeader">
                                        <!-- <th table-header-checkbox></th> -->
                                        <th trans>Title</th>
                                        <th trans>Artist</th>
                                        <th trans>Genre</th>
                                        <th trans>BPM</th>
                                        <th trans>Date</th>
                                        <th trans>Download Times</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let gaana of  gaane$ | async; let _index = index">
                                        <tr>
                                            <td>{{gaana?.name || '-'}}
                                                <span *ngIf="gaana?.track_versions_name">({{gaana?.track_versions_name
                                                    || '-'}})</span>
                                            </td>
                                            <td>
                                                <artists-links-list class="secondary" [track]="gaana">
                                                </artists-links-list>
                                            </td>
                                            <td data-label="Last Name">{{gaana?.genres[0]?.name || '-'}}</td>
                                            <td data-label="Last Name">{{gaana?.bpm || '-'}}</td>
                                            <td data-label="Assigned At">{{gaana?.user_downloads[gaana?.user_downloads.length-1]?.updated_at |
                                                date:"MM/dd/yyyy"}}</td>
                                            <td>
                                                <ng-container *ngFor="let item of [].constructor(3); let i = index">
                                                    <i [ngClass]="((i+1)<= gaana.number_of_times)? 'text-success' : ''"
                                                        class="bi bi-circle-fill song-download-circle me-1"></i>
                                                </ng-container>
                                                <!-- <i [ngClass]="isDownloaded(1, gaana.user_downloads)"
                                                    class="bi bi-circle-fill song-download-circle me-1"></i>
                                                <i [ngClass]="isDownloaded(2, gaana.user_downloads)"
                                                    class="bi bi-circle-fill song-download-circle me-1"></i>
                                                <i [ngClass]="isDownloaded(3, gaana.user_downloads)"
                                                    class="bi bi-circle-fill song-download-circle me-1"></i> -->
                                            </td>
                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                            <no-results-message svgImage="music.svg" *ngIf="datatable.paginator.noResults$ | async">
                                <span primary-text>No albums have been created yet</span>
                            </no-results-message>
                            <datatable-footer></datatable-footer>
                        </div>

                        <!-- <section class="">

                            <div class="list-of-songs">
                                <table class="table download-history-table">
                                    <thead>
                                        <tr class="responsive-class1">
                                            <th class="pb-3">Title</th>
                                            <th class="pb-3">Artist</th>
                                            <th class="pb-3">Genre</th>
                                            <th class="pb-3">BPM</th>
                                            <th class="pb-3">Date</th>
                                            <th class="pb-3 download-times">Download Times</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr class="responsive-class2">
                                            <td>Do It To It - Kwest vs Acraze Edit</td>
                                            <td>Cherish</td>
                                            <td>House</td>
                                            <td>126</td>
                                            <td>11/29/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i></td>
                                        </tr>

                                        <tr class="responsive-class2">
                                            <td>The Motto - Jablonski & Tejeda...</td>
                                            <td>Tiësto & Ava...</td>
                                            <td>House</td>
                                            <td>124</td>
                                            <td>11/28/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i></td>
                                        </tr>

                                        <tr class="responsive-class2">
                                            <td>Icy Chain - Tall Boys 100-75 Tr...</td>
                                            <td>Saweetie</td>
                                            <td>Hip Hop</td>
                                            <td>100</td>
                                            <td>11/27/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i></td>
                                        </tr>



                                        <tr class="responsive-class2">
                                            <td> 21 Questions - Camtrao &...</td>
                                            <td>50 Cent (ft...</td>
                                            <td>Hip Hop</td>
                                            <td>104</td>
                                            <td>11/26/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i></td>
                                        </tr>



                                        <tr class="responsive-class2">
                                            <td>Do It To It - Dawg Remix</td>
                                            <td>Acraze (ft. ...</td>
                                            <td>R&B</td>
                                            <td>64.5</td>
                                            <td>11/25/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i></td>
                                        </tr>



                                        <tr class="responsive-class2">
                                            <td> D O D O - Da Phonk Club Edit </td>
                                            <td>Tayc</td>
                                            <td>Others</td>
                                            <td>99</td>
                                            <td>11/24/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i>
                                            </td>
                                        </tr>



                                        <tr class="responsive-class2">
                                            <td>The Motto - Isakone Remix</td>
                                            <td>Tiësto & Ava...</td>
                                            <td>House</td>
                                            <td>126</td>
                                            <td>11/23/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i>
                                            </td>
                                        </tr>



                                        <tr class="responsive-class2">
                                            <td>Estrella - BROSS Club Edit</td>
                                            <td>Dalex, Totoy...</td>
                                            <td>Latin</td>
                                            <td>88</td>
                                            <td>11/22/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i>
                                            </td>
                                        </tr>



                                        <tr class="responsive-class2">
                                            <td>Bet It</td>
                                            <td>Cardi B</td>
                                            <td>Hip Hop</td>
                                            <td>54</td>
                                            <td>11/21/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i>
                                            </td>
                                        </tr>

                                        <tr class="responsive-class2">
                                            <td> Get Busy - Tiger Toast Freaks</td>
                                            <td>Sean Paul</td>
                                            <td>House</td>
                                            <td>124</td>
                                            <td>11/20/2021</td>
                                            <td class="download-time-icon"><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i><i
                                                    class="bi bi-circle song-download-circle px-1"></i>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                        </section> -->
                    </div>
                </div>
            </div>
        </div>

    </div>
</section>
