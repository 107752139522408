<div class="blog-post" innerHtml="{{page.body}}"></div>
<!-- <section>
    <div class="container-fluid">
        <div class="row justify-content-center">
            <div class="col-md-8 mb-5">
                <div class="row mb-5">
                    <div class="col-md-12" trans>
                        <h1 class="about-us-heading text-center my-5" trans>The most complete record pool.</h1>
                        <p class="text-center about-dj-info" trans>DJ Mixing Lab provides professional DJ's the best
                            platform to find and download all the latest music.</p>
                        <hr class="hr-line" trans>
                        <p class="text-center about-dj-info my-5" trans>Established in 2010, DJ Mixing Lab is the world’s
                            leading subscription-based digital record
                            pool for DJs – delivering new music to thousands of members every day.</p>
                        <p class="text-center about-dj-info mb-5" trans>By focusing exclusively on selecting the most relevant
                            music, DJ Mixing Lab has positioned
                            itself as the most trusted and respected site where DJs discover new music including mixshow
                            and club-ready versions. Membership is limited to professional DJs, and many subscribers are
                            influential hosts, MDs and PDs in the nation’s top markets.</p>
                        <p class="text-center about-dj-info mb-5" trans>DJcity is also a leading source for DJ and producer
                            culture delivering exclusive
                            performances, interviews, behind the scenes, tutorials and more to fans around the world.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section> -->
