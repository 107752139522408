<div class="datatable responsive-table">
    <datatable-header pluralResourceName="lyrics">
        <ng-container leftContent>
            <button mat-raised-button (click)="openCrupdateLyricModal()" color="accent" *ngIf="currentUser.hasPermission('lyrics.create')" headerActions trans>Add</button>
        </ng-container>
        <button rightContent mat-raised-button color="accent" (click)="confirmLyricsDeletion()" *ngIf="currentUser.hasPermission('lyrics.delete')" trans>Delete Selected</button>
    </datatable-header>
    <table class="material-table">
        <thead>
        <tr>
            <th table-header-checkbox></th>
            <th table-sort-header="track_id" trans>Track</th>
            <th trans>Artist</th>
            <th trans>Album</th>
            <th table-sort-header="updated_at" trans>Last Updated</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let lyric of lyrics$ | async">
            <td [table-body-checkbox]="lyric.id"></td>
            <td data-label="Track">
                <div class="column-with-image">
                    <img [src]="getLyricImage(lyric)" alt="Track image">
                    {{lyric.track?.name || '-'}}
                </div>
            </td>
            <td data-label="Artist">{{lyric.track?.album?.artist.name || '-'}}</td>
            <td data-label="Album">{{lyric.track?.album?.name || '-'}}</td>
            <td data-label="Last Updated">{{lyric.updated_at | formattedDate}}</td>
            <td class="edit-column">
                <button mat-icon-button (click)="openCrupdateLyricModal(lyric)" *ngIf="currentUser.hasPermission('lyrics.update')" [matTooltip]="'Edit lyric' | trans">
                    <mat-icon svgIcon="edit"></mat-icon>
                </button>
            </td>
        </tr>
        </tbody>
    </table>
    <no-results-message svgImage="source-code.svg" *ngIf="datatable.paginator.noResults$ | async">
        <span primary-text>No lyrics have been created yet</span>
    </no-results-message>
    <datatable-footer></datatable-footer>
</div>
