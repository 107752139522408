<!-- <div class="blog-post" innerHtml="{{page.body}}"></div> -->
<div class="container-fluid blog-post">
    <div name="termly-embed" data-id="5bc8d53c-add6-4f61-af66-26f1c34449cb"></div>
</div> 
<!-- <div class="container-fluid">
    <div class="privacy-policy-wrapper">
        <h1 class="privacy-policy-heading">
            Privacy Policy
        </h1>

        <h4 class="personal-information-heading">
            Collection and Use of Personal Information
        </h4>
        <p class="personal-info">We collect the following personal information from you:</p>

        <div class="information-list my-5">
            <ul>
                <li>Contact Information such as name, email address, mailing address, phone number</li>
                <li>Billing Information such as credit card number, and billing address</li>
                <li>Financial Information such as bank or brokerage account numbers, types of investments</li>
                <li>Unique Identifiers such as user name, account number, password</li>
                <li>Preferences Information such as product wish lists, order history, marketing preferences</li>
            </ul>
        </div>

        <h4>
            Collection and Use of Personal Information
        </h4>
        <p class="mb-5">Demographic information such as age, education, gender, interests and zip code. As is true of most Web sites,
            we automatically gather information about your computer such as your IP address, browser type,
            referring/exit pages, and operating system.</p>

            <h4>Choice/Opt-Out</h4>
            <p class="mb-5">You may choose to stop receiving our newsletter or marketing emails by following the unsubscribe instructions included in these emails or you can contact us at info@bpmsupreme.com. We enable you to create a profile, and share information such as messages, photos, and videos with others. Please note that we cannot control the actions of others with whom you may choose to share your pages and information.</p>

            <h4>We may also disclose your personal information</h4>
            <ul>
                <li>As required by law such as to comply with a subpoena, or similar legal process</li>
                <li>When we believe in good faith that disclosure is necessary to protect our rights, protect your safety or the safety of others, investigate fraud, or respond to a government request</li>
                <li>If BPM Supreme LLC, is involved in a merger, acquisition, or sale of all or a portion of its assets, you will be notified via email and/or a prominent notice on our Web site of any change in ownership or uses of your personal information, as well as any choices you may have regarding your personal nformation</li>
                <li>To any other third party with your prior consent to do so.</li>
            </ul>

            <h4>Information Sharing</h4>
            <p>We will share your personal information with third parties only in the ways that are described in this privacy statement. We do not sell your personal information to third parties. We may provide your personal information to companies that provide services to help us with our business activities such as shipping your order or offering customer service. These companies are authorized to use your personal information only as necessary to provide these services to us.</p>

            <h4>Cookies and Other Tracking Technologies</h4>
            <p class="Cookies-Technologies">We may use cookies, for example, to keep track of your preferences and profile information. Cookies are also used to collect general usage and volume statistical information that does not include personal information. We use another company to place cookies on your computer to collect non-personally identifiable information to compile aggregated statistics for us about visitors to our site.</p>
    </div>
</div> -->
