<div class="datatable responsive-table">
    <datatable-header pluralResourceName="albums">
        <ng-container leftContent>
            <!-- <a mat-raised-button color="accent" routerLink="/admin/upload" *ngIf="currentUser.hasPermission('albums.create')" trans>Upload</a>
            <a mat-button routerLink="/admin/albums/new" *ngIf="currentUser.hasPermission('albums.create')" trans>Create</a> -->
            <a mat-raised-button color="accent" routerLink="/admin/albums/new" *ngIf="currentUser.hasPermission('albums.create')" trans>Create</a>
        </ng-container>
        <button rightContent mat-raised-button color="accent" (click)="maybeDeleteSelectedAlbums()" *ngIf="currentUser.hasPermission('albums.delete')" trans>Delete Selected</button>
    </datatable-header>
    <table class="material-table">
        <thead>
        <tr>
            <th table-header-checkbox></th>
            <th table-sort-header="name" trans>Album</th>
            <th table-sort-header="artist_id" trans>Artist</th>
            <th table-sort-header trans>Release Date</th>
            <th table-sort-header="spotify_popularity" trans>Popularity</th>
            <th table-sort-header="tracks_count" trans>Number Of Tracks</th>
            <th table-sort-header="views" trans>Views</th>
            <th table-sort-header="updated_at" trans>Last Updated</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let album of albums$ | async">
            <td [table-body-checkbox]="album.id"></td>
            <td>
                <div class="column-with-image">
                    <media-image [media]="album" default="album"></media-image>
                    <a [routerLink]="urls.album(album)" class="link">{{album.name}}</a>
                </div>
            </td>
            <td data-label="Artist">
                <div class="column-with-image" *ngIf="album.artist as artist">
                    <media-image [media]="artist" default="artist"></media-image>
                    <a [routerLink]="urls.artist(artist)" class="link">{{artist.name}}</a>
                </div>
            </td>
            <td data-label="Release Date">{{album.release_date ? album.release_date: '&ndash;'}}</td>
            <td data-label="Popularity">{{album.spotify_popularity}}</td>
            <td data-label="Number of Tracks">{{album.tracks_count ? album.tracks_count: '&ndash;'}}</td>
            <td data-label="Views">{{album.views ? album.views : '&ndash;'}}</td>
            <td data-label="Last Updated">{{album.updated_at | formattedDate}}</td>
            <td class="edit-column">
                <a mat-icon-button [routerLink]="['/admin/albums', album.id]" *ngIf="currentUser.hasPermission('albums.update')" [matTooltip]="'Edit album' | trans">
                    <mat-icon svgIcon="edit"></mat-icon>
                </a>
            </td>
        </tr>
        </tbody>
    </table>
    <no-results-message svgImage="music.svg" *ngIf="datatable.paginator.noResults$ | async">
        <span primary-text>No albums have been created yet</span>
    </no-results-message>
    <datatable-footer></datatable-footer>
</div>
