<section *ngIf="!showTrendingList">
    <table class="material-table" [class.mobile]="state.isMobile" cdkDropList [cdkDropListDisabled]="!reorderEnabled"
        (cdkDropListDropped)="orderChanged.emit($event)">
        <thead *ngIf="!state.isMobile && showHeader">
            <tr class="customHeader">
               <!--  <th class="number-column" *ngIf="!state.isMobile">#</th> -->
                <th table-sort-header="name" trans>Title</th>
                <th table-sort-header="artists.name" trans class="responsive-table-data">Artist</th>
                <th table-sort-header="genres.name" trans class="responsive-table-data">Genre</th>
                <th table-sort-header="bpm" trans class="responsive-table-data">BPM</th>
                <th table-sort-header="key" trans class="responsive-table-data">Key</th>
                <th table-sort-header="created_at" trans>Date</th>
            </tr>
        </thead>
        <tbody>
           <!--  <tr *ngFor="let track of dataSource.data$ | async; trackBy: trackByFn; let i = index"
                [class.selected]="selectedTracks.has(track)" [attr.data-id]="track.id"
                [class.playing]="trackIsPlaying(track)" [contextMenu]="getContextMenuParams(track)"
                class="track-list-row" cdkDrag>
                <td *ngIf="!state.isMobile" class="number-column">
                    <span class="number">{{i+1}}</span>
                    <playback-control-button class="small" [track]="track" (play)="playTrack(track, i)"
                        (pause)="player.pause()"></playback-control-button>
                </td>
                <td class="name-column">{{track.name}}</td>
                <td class="artist-column">
                    <artists-links-list [track]="track"></artists-links-list>
                </td>
                <td data-label="Last Name">{{(track && track.genres && track.genres.length > 0) ?
                    track?.genres[0]?.name:'-'}}</td>
                <td data-label="Last Name">{{track?.bpm || '-'}}</td>
                <td data-label="Last Name">{{track?.t_key || '-'}}</td>
                <td data-label="Assigned At">{{track?.date | formattedDate}}</td>
            </tr> -->
            <ng-container *ngFor="let gaana of gaane; trackBy: trackByFn; let _index = index">
        <tr [class.selected]="selectedTracks.has(gaana)" [attr.data-id]="gaana.id"
          [class.playing]="trackIsPlaying(gaana)" class="track-list-row">
          <td><a  class="d-flex align-items-center text-truncate" [ngClass]="isDownloaded(1, gaana.user_downloads)?'text-download':'text-center'" style="max-width: 200px;"
              trans>
              <!-- <i class="bi bi-play-circle-fill play-circle-icon"></i> -->
              <playback-control-button class="small me-2" [track]="gaana" (play)="playTrack(gaana,_index)" 
                (pause)="player.pause()"></playback-control-button>
              {{gaana?.name}}
            </a></td>
          <td data-label="First Name" class="text-truncate" style="max-width: 200px;" trans>
            {{gaana?.artists && (gaana?.artists.length>0)?gaana?.artists[0]?.name : '-'}}</td>
          <td data-label="Last Name" trans>{{gaana?.genres && (gaana?.genres.length>0)?gaana?.genres[0]?.name : '-'}}</td>
          <td data-label="Last Name" trans>{{gaana?.bpm || '-'}}</td>
          <td data-label="Last Name" trans>{{gaana?.t_key || '-'}}</td>
          <td data-label="Assigned At" trans>{{gaana?.date | date:"MM/dd/yyyy"}}</td> 
          <td class="eclipse"><a data-bs-toggle="collapse" href="#collapseDownload{{_index}}" (click)="expandRow(_index)">
            <i *ngIf="!urls.expandedIndex.includes(_index)" class="bi bi-three-dots collapse-icon"></i>
            <i *ngIf="urls.expandedIndex.includes(_index)" class="bi bi-three-dots-vertical collapse-icon"></i>
          </a>
          </td>
        </tr>
        <tr class="--custom-height-unset">
          <td colspan="7" class="p-0">
            <div class="collapse" id="collapseDownload{{_index}}">
              <table class="table modal-details mt-3">
                <tr class="text-white downloaded-songs-list">
                  <td></td>
                  <td class="downloaded-song" trans>{{gaana?.name}}</td>
                  <td trans>
                    <i [ngClass]="isDownloaded(1, gaana.user_downloads)"
                      class="bi bi-circle-fill song-download-circle"></i>
                    <i [ngClass]="isDownloaded(2, gaana.user_downloads)"
                      class="bi bi-circle-fill song-download-circle"></i>
                    <i [ngClass]="isDownloaded(3, gaana.user_downloads)"
                      class="bi bi-circle-fill song-download-circle"></i>
                  </td>
                  <td>
                    <a (click)="downloadTrack(gaana?.id, _index)"><i
                        class="bi bi-download song-download-icon"></i></a>
                  </td>
                </tr>
                <ng-container *ngIf="gaana?.track_versions.length > 0">
                  <tr *ngFor="let version of gaana?.track_versions; let verIndex = index;"
                    class="text-white border-top-0 downloaded-songs-list">
                    <td trans [ngClass]="isVerDownloaded(1, version?.download_count)?'text-download':'text-truncate'">
                      <playback-control-button class="small me-2" [track]="version" (play)="playVersionTrack(gaana?.track_versions,version,verIndex)"
                (pause)="player.pause()"></playback-control-button>
                  </td>
                    <td trans [ngClass]="isVerDownloaded(1, version?.download_count)?'text-download':'text-truncate'">{{version?.name}}</td>
                    <td>
                      <i [ngClass]="isVerDownloaded(1, version?.download_count)"
                        class="bi bi-circle-fill song-download-circle"></i>
                      <i [ngClass]="isVerDownloaded(2, version?.download_count)"
                        class="bi bi-circle-fill song-download-circle"></i>
                      <i [ngClass]="isVerDownloaded(3, version?.download_count)"
                        class="bi bi-circle-fill song-download-circle"></i>
                    </td>
                    <td>
                      <a (click)="downloadTrackVersion(gaana?.id, version?.id, _index, verIndex)"><i class="bi bi-download song-download-icon"></i></a>
                    </td>
                  </tr>
                </ng-container>

              </table>
            </div>
          </td>
        </tr>
      </ng-container>
        </tbody>
    </table>
    <datatable-footer></datatable-footer>
</section>

<section *ngIf="showTrendingList">
    <table class="material-table" [class.mobile]="state.isMobile" cdkDropList [cdkDropListDisabled]="!reorderEnabled"
        (cdkDropListDropped)="orderChanged.emit($event)">
        <tbody>
            <tr *ngFor="let track of dataSourceTrending.data$ | async; trackBy: trackByFn; let i = index"
                [class.selected]="selectedTracks.has(track)" [attr.data-id]="track.id"
                [class.playing]="trackIsPlaying(track)"
                class="track-list-row">
                <td class="number-column">
                    <span class="number">{{i+1}}</span>
                    <playback-control-button class="small" [track]="track" (play)="playTrack(track, i)"
                        (pause)="player.pause()"></playback-control-button>
                </td>
                <td class="name-column">{{track.name}}
                    <p>
                        <artists-links-list [track]="track"></artists-links-list>
                    </p>
                </td>
                <!-- <td class="options-column">
                    <button mat-icon-button class="track-options-button" (click)="showContextMenu(track, $event)"
                        [matTooltip]="'Show options' | trans">
                        <mat-icon svgIcon="more-horiz"></mat-icon>
                    </button>
                </td> -->
            </tr>
        </tbody>
    </table>
</section>
<loading-indicator class="overlay" [isVisible]="loading$ | async"></loading-indicator>