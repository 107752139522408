<div class="elapsed-time" #elapsedTimeEl>0:00</div>
<!-- <div class="outer-track" #outerTrack hidden>
  <div class="inner-track"></div>
  <div class="progress-track" #progressTrack></div>
  <div class="progress-handle" #progressHandle></div>
</div> -->
<div class="wave-form px-1" #outerTrack>
  <div id="waveform" class="inner-track"></div>
</div>
<div class="track-length" #trackLengthEl>0:00</div>
