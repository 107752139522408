<ng-container *ngIf="!panel.noResults">
    <!-- <a [routerLink]="urls.search(panel.searchQuery.value)" class="view-all-link">
        <div trans>View All Results...</div>
        <mat-icon svgIcon="keyboard-arrow-right"></mat-icon>
    </a> -->

    <!-- <div class="artist-results" *ngIf="panel.results.artists?.length">
        <div class="result-type" trans>Artists</div>

        <div class="artists">
            <div class="result-with-image" *ngFor="let artist of panel.results.artists" [contextMenu]="{item: artist, type: 'artist'}">
                <a [routerLink]="urls.artist(artist)" class="img-container">
                    <media-image [media]="artist"></media-image>
                </a>
                <div class="names">
                    <a class="primary hover-underline" [routerLink]="urls.artist(artist)">{{artist.name}}</a>
                    <div class="secondary" trans>Artist</div>
                </div>
            </div>
        </div>
    </div> -->

    <div class="track-results" *ngIf="panel.results.tracks?.length">
        <div class="result-type" trans>Songs</div>

        <div class="tracks">
            <!-- <div class="row result-with-image track" *ngFor="let track of panel.results.tracks" [contextMenu]="{item: track, type: 'track'}" (click)="goToTrackPage(track)"> -->
            <div class="row result-with-image track" *ngFor="let track of panel.results.tracks" [contextMenu]="{item: track, type: 'track'}">
                <div class="col-2 img-container">
                    <playback-control-button [track]="track" (play)="playTrack(track)" (pause)="pausePlayer()"></playback-control-button>
                    <!-- <media-image [media]="track"></media-image> -->
                </div>
                <div class="col-10 song-names">
                    <!-- <a class="primary hover-underline" [routerLink]="urls.track(track)">{{track.name}}</a> -->
                    <span class="primary hover-underline">{{track.name}}</span>
                    <artists-links-list class="secondary" [track]="track"></artists-links-list>
                </div>
            </div>
        </div>
    </div>

    <!-- <div class="album-results" *ngIf="panel.results.albums?.length">
        <div class="result-type" trans>Albums</div>

        <div class="albums">
            <div class="result-with-image" *ngFor="let album of panel.results.albums" [contextMenu]="{item: album, type: 'album'}">
                <a [routerLink]="urls.album(album)" class="img-container">
                    <media-image [media]="album"></media-image>
                </a>
                <div class="names">
                    <a class="primary hover-underline" [routerLink]="urls.album(album)">{{album.name}}</a>
                    <a class="secondary hover-underline" *ngIf="album.artist" [routerLink]="urls.artist(album.artist)">{{album.artist.name}}</a>
                </div>
            </div>
        </div>
    </div> -->

    <div class="playlist-results" *ngIf="panel.results.playlists?.length">
        <div class="result-type" trans>Playlists</div>

        <div class="playlists">
            <div class="result-with-image" *ngFor="let playlist of panel.results.playlists" [contextMenu]="{item: playlist, type: 'playlist'}">
                <ng-container *ngIf="playlist.editors && playlist.editors[0]">
                    <!--<a [routerLink]="urls.playlist(playlist)" class="img-container">
                        <media-image [media]="playlist"></media-image>
                    </a>-->
                    <!-- <a (click)="openDetails(playlist)" href="javascript:;" class="img-container">
                        <media-image *ngIf="playlist.image!=null" [media]="playlist"></media-image>
                    </a> -->
                    <div class="names">
                        <a href="javascript:;" class="primary hover-underline" (click)="openDetails(playlist)">{{playlist.name}}</a>
                        <!-- <a class="primary hover-underline" [routerLink]="urls.playlist(playlist)">{{playlist.name}}</a> -->
                        <span class="secondary">
                        <!-- <span trans>By</span>&nbsp;<a class="hover-underline" [routerLink]="urls.user(playlist.editors[0])">{{playlist.editors[0]['display_name']}}</a> -->
                        <span trans>By</span>&nbsp;{{playlist.editors[0]['display_name']}}</span>
                    </div>
                </ng-container>
            </div>
        </div>
    </div>

    <!-- <div class="user-results" *ngIf="panel.results.users?.length">
        <div class="result-type" trans>People</div>

        <div class="users">
            <div class="result-with-image" *ngFor="let user of panel.results.users">
                <a [routerLink]="urls.user(user)" class="img-container">
                    <media-image [media]="user"></media-image>
                </a>
                <div class="names">
                    <a class="primary hover-underline" [routerLink]="urls.user(user)">{{user['display_name']}}</a>
                    <span class="secondary" *ngIf="user.followers_count">{{user.followers_count}} <span trans>Followers</span></span>
                </div>
            </div>
        </div>
    </div> -->
</ng-container>
<no-results-message *ngIf="panel.noResults && !panel.searching">
    <span primary-text trans>No results found.</span>
    <span secondary-text trans>Please check your spelling or try using different keywords.</span>
</no-results-message>
