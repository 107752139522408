<div class="modal-header">
    <h2 mat-dialog-title trans>
        <span *ngIf="!lyric.id" trans>Add to Playlist</span>
    </h2>
    <button type="button" mat-icon-button (click)="close()" class="close-button" tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>
<form (ngSubmit)="addtolist()" [formGroup]="form">
    <mat-dialog-content class="many-inputs">
        <div class="input-container">
            <label for="genre-name" trans>Playlist</label>
            <select formControlName="playlist">
                <option value="">Select Playlist</option>
                <option value="{{playlist.id}}" *ngFor="let playlist of allPlayList">{{playlist.name}}</option>
            </select>
            <!-- <input type="text" id="genre-name"  required> -->
            <div class="error" *ngIf="formSubmit && iserror()">This is a required field.</div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="cancel-button" trans>Close</button>
        <button type="submit" mat-raised-button color="accent" class="submit-button" trans>
            <span>Add</span>
        </button>
    </mat-dialog-actions>

    <loading-indicator [isVisible]="loading" class="overlay opacity-high"></loading-indicator>
</form>


<div class="modal-header" style="display: none;">
    <h2 mat-dialog-title trans>
        <span *ngIf="lyric.id" trans>Update Lyric</span>
        <span *ngIf="!lyric.id" trans>New Lyric</span>
    </h2>
    <button type="button" mat-icon-button (click)="close()" class="close-button" tabindex="-1">
        <mat-icon svgIcon="close"></mat-icon>
    </button>
</div>

<form (ngSubmit)="confirm()" ngNativeValidate style="display: none;">
    <mat-dialog-content class="many-inputs">
        <div class="input-container media-autocomplete-input-container">
            <label for="lyrics-track-name" class="hidden" trans>Track</label>
            <media-image [media]="track" *ngIf="track"></media-image>
            <input
                    type="text"
                    id="lyrics-track-name"
                    name="lyrics-track-name"
                    [formControl]="trackInput.formControl"
                    [matAutocomplete]="trackAuto"
                    placeholder="Search tracks..."
                    trans-placeholder
                    required
            >
            <p *ngIf="track">{{track.name}} | {{track.artists ? artistsString(track.artists) : '-'}} | {{track.album?.release_date}}</p>
            <div class="error" *ngIf="errors.track_id">{{errors.track_id}}</div>

            <mat-autocomplete #trackAuto="matAutocomplete" [displayWith]="trackDisplayFn" class="media-autocomplete-panel" (optionSelected)="attachTrack($event)">
                <mat-option *ngFor="let track of trackInput.searchResults | async" [value]="track">
                    <media-image [media]="track"></media-image>
                    <div class="multiple-lines">
                        <div>{{ track.name }} -&nbsp;{{track.artists ? artistsString(track.artists) : ''}}</div>
                        <div class="secondary" *ngIf="track.album">{{track.album.name}} - {{track.album.release_date}}</div>
                    </div>
                </mat-option>
            </mat-autocomplete>
        </div>


        <div class="input-container">
            <text-editor class="text-editor" [minHeight]="400" [basic]="true" #textEditor></text-editor>
            <div class="error" *ngIf="errors.text">{{errors.text}}</div>
        </div>
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="cancel-button" trans>Close</button>
        <button type="submit" mat-raised-button color="accent" class="submit-button" [disabled]="loading" trans>
            <span *ngIf="lyric.id" trans>Update</span>
            <span *ngIf="!lyric.id" trans>Create</span>
        </button>
    </mat-dialog-actions>
</form>
