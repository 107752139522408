<div class="input-container">
    <label for="storage_rackspace_username" trans>Rackspace Username</label>
    <input type="text" id="storage_rackspace_username" name="storage_rackspace_username" [(ngModel)]="state.server['storage_rackspace_username']" required>
</div>

<div class="input-container">
    <label for="storage_rackspace_key" trans>Rackspace Key</label>
    <input type="text" id="storage_rackspace_key" name="storage_rackspace_key" [(ngModel)]="state.server['storage_rackspace_key']" required>
</div>

<div class="input-container">
    <label for="storage_rackspace_region" trans>Rackspace Region</label>
    <input type="text" id="storage_rackspace_region" name="storage_rackspace_region" [(ngModel)]="state.server['storage_rackspace_region']" required>
</div>

<div class="input-container">
    <label for="storage_rackspace_container" trans>Rackspace Container</label>
    <input type="text" id="storage_rackspace_container" name="storage_rackspace_container" [(ngModel)]="state.server['storage_rackspace_container']" required>
</div>
