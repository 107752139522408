<form (ngSubmit)="submit()" [formGroup]="form">
    <div class="main-info-fields">
        <div class="left-column">
            <upload-image-control formControlName="image" uploadType="album"></upload-image-control>
            <!-- <button mat-flat-button color="accent" type="button" class="upload-tracks-button" [uploadButton]="uploadButtonConfig" (filesSelected)="uploadFiles($event)" trans>Upload Tracks</button> -->
        </div>
        <div class="text-fields-container many-inputs">
            <div class="input-container">
                <label for="album-form-name" trans>Name</label>
                <input type="text" id="album-form-name" formControlName="name" required>
                <div class="error" *ngIf="errors.name">{{errors.name}}</div>
            </div>

            <div class="input-container">
                <label for="album-form-release_date" trans>Release Date</label>
                <input type="date" placeholder="YYYY-MM-DD" id="album-form-release_date" formControlName="release_date" required>
                <div class="error" *ngIf="errors.release_date">{{errors.release_date}}</div>
            </div>

            <div class="input-container" *ngIf="!hideArtistControl || (!artist && settings.get('player.artist_type') === 'artist')">
                <label for="album-form-artist" trans>Artist</label>
                <album-artist-control id="album-form-artist" formControlName="artist"></album-artist-control>
                <div class="error" *ngIf="errors.artist_id">{{errors.artist_id}}</div>
            </div>

            <div class="input-container">
                <label for="album-form-description" trans>Description</label>
                <textarea type="text" id="album-form-description" formControlName="description" minlength="10" maxlength="250" rows="5"></textarea>
                <div class="error" *ngIf="errors.description">{{errors.description}}</div>
            </div>

            <div class="input-container">
                <label for="album-form-genres" trans>Genres</label>
                <chip-input placeholder="+Add genre" id="album-form-genres" [suggestFn]="suggestGenreFn" formControlName="genres"></chip-input>
                <div class="error" *ngIf="errors.genres">{{errors.genres}}</div>
            </div>

            <div class="input-container">
                <label for="album-form-tags" trans>Tags</label>
                <chip-input placeholder="+Add tag" id="album-form-tags" formControlName="tags" [suggestFn]="suggestTagFn"></chip-input>
                <div class="error" *ngIf="errors.tags">{{errors.tags}}</div>
            </div>

            <button type="submit" [disabled]="loading$ | async" mat-raised-button color="accent" trans>Save</button>
            <button type="button" mat-button (click)="maybeCancel()" trans>Cancel</button>
        </div>
    </div>
</form>

<ul class="track-list unstyled-list" cdkDropList (cdkDropListDropped)="reorderTracks($event)">
    <li class="track-list-item" *ngFor="let track of allTracks$ | async; trackBy: trackByFn; index as index" cdkDrag>
        <track-form [albumForm]="form" [track]="track" *ngIf="track.model_type" [number]="index + 1" (canceled)="trackRemoved(track)">
            <button mat-icon-button cdkDragHandle type="button">
                <mat-icon svgIcon="drag-handle"></mat-icon>
            </button>
        </track-form>
        <track-form [albumForm]="form" [uploadQueueItem]="track" *ngIf="!track.model_type" [number]="index + 1" (canceled)="trackRemoved(track)">
            <button mat-icon-button cdkDragHandle type="button">
                <mat-icon svgIcon="drag-handle"></mat-icon>
            </button>
        </track-form>
    </li>
</ul>
