<section>
    <div class="container-fluid">
        <div class="music-source-wrapper py-5" trans>
            <h1 class="text-center mb-4" trans>Performance ready files for all playforms</h1>
            <p class="text-center" trans>Analyzed Waveforms. Cue Points. Properly formatted ID3 Tags with Key Signature & BPM.
            </p>
            <div class="music-source-logo">
                <div class="row align-items-center">
                    <div class="col-6 col-md-3 text-center">
                        <img src="assets/img/serato_dark_logo (1).png" class="img-fluid light-theme-logo" alt="">
                        <img src="assets/img/serato_white_logo.png" class="img-fluid dark-theme-logo" alt="">
                    </div>
                    <div class="col-6 col-md-3 text-center">
                        <img src="assets/img/rekordbox_dark_logo.png" class="img-fluid light-theme-logo" alt="">
                        <img src="assets/img/rekordbox_white_logo (1).png" class="img-fluid dark-theme-logo" alt="">
                    </div>
                    <div class="col-6 col-md-3 text-center">
                        <img src="assets/img/traktor_dark_logo.png" class="img-fluid light-theme-logo" alt="">
                        <img src="assets/img/traktor_white_logo (1).png" class="img-fluid dark-theme-logo" alt="">
                    </div>
                    <div class="col-6 col-md-3 text-center">
                        <img src="assets/img/virtualdj_dark_logo (1).png" class="img-fluid light-theme-logo" alt="">
                        <img src="assets/img/virtualdj_white_logo.png" class="img-fluid dark-theme-logo" alt="">
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
