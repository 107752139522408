<div class="container">
    <div class="row justify-content-center">
        <div class="col-md-8">
            <div class="Disclaimer-border">
                <div class="row">
                    <div class="col-md-12">
                        <h1 class="disclaimer-heading" trans> Disclaimer</h1>
                        <div class="scroll-wrapper">
                            <p trans> This website is strictly for <span class="highlight-text" trans>DJs / VJs</span> and not for the general public.
                                This is NOT a
                                consumer resource for musical and/or video works; and the assets described
                                herein
                                are not made available to the general public. If you are not a <span class="highlight-text">DJ/VJ</span> or
                                <span class="highlight-text" trans>Label Rep,</span>
                                leave now.
                            </p>

                            <p trans> VJ Street is a VJ Remix service & Music video resource for non-broadcast DJ/VJ's
                                for
                                <span class="highlight-text" trans>PROMOTIONAL</span> use in public performance displays ONLY. Professional DJ/VJ's
                                and music
                                producers have remixed the content contained on VJ Street for the sole purpose
                                of
                                enhancing an overall VJ performance. The services and resources on this site are
                                made available under specific licensing and permissions granted by the original
                                copyright holders under usage and display definitions in accordance with United
                                States Copyright Code Title 17; Section 106 for use in ASCAP, BMI and SESAC
                                compliant venues.
                            </p>

                            <p trans>By clicking Agree, you are certifying that you are a working DJ/VJ and will use
                                the
                                material on VJ Street for the sole purpose of enhancing your performance as a
                                DJ/VJ,
                                that you will not resale, reproduce, post, redistribute or broadcast any of the
                                content on VJ Street, that you certify you will ONLY use the material on VJ
                                Street
                                for the sole purpose of enhancing your performance as a DJ/VJ. The content
                                obtained
                                on this site is for PROMOTIONAL purposes ONLY This Website uses cookies to offer
                                you
                                the best browsing experience to comply with regulations</p>
                        </div>
                        <div class="disclaimer-buttons mt-4">
                            <a href="javascript:void(0)" type="button" class="btn btn-outline-secondary  disagree-button"
                                data-bs-toggle="modal" data-bs-target="#myModal" trans>Disagree</a>
                            <!-- <a class="btn btn-success agree-button" href="#">Agree</a> -->
                            <a href="javascript:void(0)" routerLink="/" (click)="agree()" class="btn btn-success agree-button" trans>
                                Agree
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<term-of-services id="myModal" class="modal fade"></term-of-services>
