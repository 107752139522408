<section>

    <div class="container-fluid py-0 my-0 py-md-5 my-md-5">
        <div class="row mt-5">
            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-music-note-list "></i>
                    <h5 trans>Daily Releases</h5>
                    <p trans>We upload at least 500 files a week guaranteeing you fresh tracks to browse & download.</p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-bookmark-star"></i>
                    <h5 trans>Top Downloads</h5>
                    <p trans>Never miss trending tracks by checking out our top 50 most downloaded songs by month.</p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-search"></i>
                    <h5 trans>Advanced Search</h5>
                    <p trans>Find tracks effortlessly by using our powerful search & filters tools.</p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-collection"></i>
                    <h5 trans>Genres</h5>
                    <p trans>Our extensive music library has 57 genres. We got you covered.</p>
                </div>
            </div>


            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-file-earmark-play"></i>
                    <h5 trans>File Quality</h5>
                    <ul class="p-0">
                        <li trans>Perfect id3 tags </li>
                        <li trans>Serato cue points </li>
                        <li trans>Waveform overview </li>
                        <li trans>Waveform overview </li>
                        <li trans>High-quality audio (320 kbps)</li>
                    </ul>
                </div>
            </div>

            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-collection-play"></i>
                    <h5 trans>Music Preview</h5>
                    <p trans>Preview & scroll through music easily using our waveform player</p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-download"></i>
                    <h5 trans>Fast Downloads</h5>
                    <p trans>We use dedicated servers to provide maximum download speeds</p>
                </div>
            </div>

            <div class="col-md-3">
                <div class="Daily-Releases-wrapper text-center" trans>
                    <i class="bi bi-cloud-moon"></i>
                    <h5 trans>Day/Night Mode</h5>
                    <p trans>Optimize your viewing experience by switching between day/night mode.</p>
                </div>
            </div>
        </div>
    </div>
</section>
