<div class="datatable responsive-table">
    <datatable-header pluralResourceName="artists">
        <ng-container leftContent>
            <a mat-raised-button color="accent" routerLink="new" *ngIf="currentUser.hasPermission('artists.create')" headerActions trans>Add</a>
        </ng-container>
        <button rightContent mat-raised-button color="accent" (click)="maybeDeleteSelectedArtists()" *ngIf="currentUser.hasPermission('artists.delete')" trans>Delete Selected</button>
    </datatable-header>
    <table class="material-table">
        <thead>
        <tr>
            <th table-header-checkbox></th>
            <th table-sort-header="name" trans>Artist1</th>
            <th table-sort-header="spotify_popularity" trans>Popularity</th>
            <!-- <th table-sort-header="albums_count" trans>Number of Albums</th> -->
            <th table-sort-header trans>Page Views</th>
            <th table-sort-header="updated_at" trans>Last Updated</th>
            <th></th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let artist of artists$ | async">
            <td [table-body-checkbox]="artist.id"></td>
            <td>
                <div class="column-with-image">
                    <media-image [media]="artist"></media-image>
                    <a [routerLink]="urls.artist(artist)" class="link" target="_blank">{{artist.name}}</a>
                </div>
            </td>
            <td data-label="Popularity">{{artist.spotify_popularity}}</td>
            <!-- <td data-label="Number of Albums">{{artist.albums_count}}</td> -->
            <td data-label="Page Views">{{artist.views}}</td>
            <td data-label="Last Updated">{{artist.updated_at | formattedDate}}</td>
            <td class="edit-column">
                <a mat-icon-button [routerLink]="[artist.id, 'edit']" *ngIf="currentUser.hasPermission('artists.update')" [matTooltip]="'Edit artist' | trans">
                    <mat-icon svgIcon="edit"></mat-icon>
                </a>
            </td>
        </tr>
        </tbody>
    </table>
    <no-results-message svgImage="music.svg" *ngIf="datatable.paginator.noResults$ | async">
        <span primary-text>No artists have been created yet</span>
    </no-results-message>
    <datatable-footer></datatable-footer>
</div>
