<home-banner></home-banner>
<!-- list-section -->
<list-section></list-section>

<!-- Extensive-Library-section -->
<extensive-library></extensive-library>

<!-- Curated-Playlist-section -->
<curated-playlist></curated-playlist>

<!-- Record-labels-section -->
<record-labels></record-labels>

<!-- Compare-Plans-section -->
<compare-plans></compare-plans>

<!-- music-source-section -->
<music-source></music-source>
