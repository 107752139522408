<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>
        <span *ngIf="updating" trans>Update Genre</span>
        <span *ngIf="!updating" trans>New Genre</span>
    </h2>
    <button type="button" (click)="close()" tabindex="-1" class="close-button no-style icon-button">
        <mat-icon svgIcon="close"></mat-icon>

    </button>
</div>

<form (ngSubmit)="confirm()" ngNativeValidate>
    <mat-dialog-content class="many-inputs">
        <div class="input-container">
            <label for="genre-name" trans>Name</label>
            <input type="text" id="genre-name" name="name" [(ngModel)]="genre.name" required>
           <!--  <p trans>Will be used in the url.</p> -->
            <div class="error" *ngIf="errors.name">{{errors.name}}</div>
        </div>
       
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="cancel-button" trans>Close</button>
        <button type="submit" mat-raised-button color="accent" class="submit-button" trans>
            <span *ngIf="updating" trans>Update</span>
            <span *ngIf="!updating" trans>Create</span>
        </button>
    </mat-dialog-actions>

    <loading-indicator [isVisible]="loading" class="overlay opacity-high"></loading-indicator>
</form>
