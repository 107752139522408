<div *ngIf="(comingSoonService.ipAddress!='73.46.111.216') && (comingSoonService.ipAddress!='122.173.30.181') && (comingSoonService.ipAddress!='122.160.138.11')" class="app-content">
    <coming-soon></coming-soon>
</div>

<div *ngIf="(comingSoonService.ipAddress=='73.46.111.216') || (comingSoonService.ipAddress=='122.173.30.181') || (comingSoonService.ipAddress=='122.160.138.11')" class="app-content">
    <header (onChangeFooterLines)="doSomething($event)" (hasAdmin)="hasAdmin($event)">
    </header>
    <router-outlet></router-outlet>
    <footer [footerLines]="footerLines" [isAdmin]="isAdmin"></footer>
</div>
