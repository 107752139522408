<mat-progress-bar mode="indeterminate" color="accent" *ngIf="datatable.paginator && datatable.paginator.loading$ | async"></mat-progress-bar>
<div class="left-column">
    <div class="default-left-content" *ngIf="!showSelectedActions || !numberOfRowsSelected">
        <ng-content select="[leftContent]"></ng-content>
    </div>
    <div class="selected-left-content capitalize" *ngIf="showSelectedActions && numberOfRowsSelected">
        {{numberOfRowsSelected}} {{ pluralResourceName }} {{ 'Selected' | trans }}
    </div>
</div>
<div class="right-column">
    <div class="default-right-content" *ngIf="!showSelectedActions || !numberOfRowsSelected">
        <ng-container *ngIf="filterPanelTemplate">
            <mat-chip-list aria-label="Active Filters" class="filter-chip-list">
                <ng-container *ngFor="let filter of filters$ | async | keyvalue">
                    <mat-chip  *ngIf="setcuskey(filter.key) != 'page'" [removable]="true" [selectable]="false" (removed)="datatable.removeFilter(filter.key)">
                        <span class="fl-key">{{ setcuskey(filter.key) }}</span> : <span class="fl-val">{{isModel(filter.value) ? (filter.value.name || filter.value.display_name) : filter.value}}</span>
                        <mat-icon matChipRemove svgIcon="cancel"></mat-icon>
                    </mat-chip>
                </ng-container>
            </mat-chip-list>
            <button (click)="openFiltersPanel()" class="filter-button" [class.active]="filterPanelRef" #filterButton mat-icon-button>
                <mat-icon svgIcon="filter-list"></mat-icon>
            </button>
        </ng-container>
        <datatable-header-search-input placeholder="Search {{ pluralResourceName }}"></datatable-header-search-input>
    </div>
    <div class="selected-right-actions" *ngIf="showSelectedActions && numberOfRowsSelected">
        <ng-content select="[rightContent]"></ng-content>
    </div>
</div>
