<div class="input-container position-relative artists-searches">
    <label [for]="id" trans>Artists</label>
    <div class="position-relative">
        <input type="text" [id]="id" [formControl]="searchControl" [matAutocomplete]="artistAuto"
            placeholder="Search artists and users..." trans-placeholder class="form-control">
        <mat-icon svgIcon="search" class="artists-search-icon"></mat-icon>
    </div>

    <mat-chip-list [selectable]="false">
        <mat-chip class="chip-with-image" *ngFor="let artist of value$ | async" [removable]="true"
            (removed)="deselectArtist(artist)">
            <img [src]="artistImage(artist.image)" [alt]="artist.name">
            {{artist.name}}
            <mat-icon svgIcon="cancel" matChipRemove></mat-icon>
        </mat-chip>
    </mat-chip-list>

    <mat-autocomplete #artistAuto="matAutocomplete" class="media-autocomplete-panel"
        (optionSelected)="selectArtist($event.option.value)" [displayWith]="displayFn">
        <mat-option *ngFor="let artist of searchResults$ | async" [value]="artist">
            <img [src]="artistImage(artist.image)" [alt]="artist.name + ' image'">
            <span>{{artist.name}}</span>
        </mat-option>
    </mat-autocomplete>

    <div class="error" *ngIf="error">{{error}}</div>
</div>