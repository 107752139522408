<section>
    <div class="rocord-pool-wrapper">
        <div class="rocord-pool">
            <div class="container-fluid h-100">
                <div class="row h-100 align-items-center">
                    <div class="col-md-6" trans>
                        <h1 class="text-white" trans>THE MOST COMPLETE RECORD POOL.</h1>
                        <p class=" my-5 custom-gray" trans>DJ Mixing Lab provides professional DJ's the best platform to
                            find and
                            download all the latest music.</p>
                            <div>
                        <a *ngIf="!currentUser.isLoggedIn()" href="javascript:void(0)" routerLink="/register" class="btn btn-success" trans>
                            Sign up now
                        </a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
