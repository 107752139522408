<div class="header">
    <div class="title" trans>Interface</div>
    <div class="subtitle" trans>Configure site interface looks and behaviour.</div>
</div>

<form class="body many-inputs" (submit)="saveSettings()" ngNativeValidate *ngIf="errors$ | async as errors">
    <div class="input-container">
        <label for="player.artist_type" trans>Artist Type</label>
        <select name="player.artist_type" id="player.artist_type" [(ngModel)]="state.client['player.artist_type']">
            <option value="user" trans>User</option>
            <option value="artist" trans>Artist</option>
        </select>
        <ul class="input-description unstyled-list">
            <li trans>User type means all music content on the site will be uploaded by users and those users are the artists for uploaded content.</li>
            <li trans>Artist type means that music content is either imported automatically or created by admin.</li>
        </ul>
    </div>

    <div class="input-container">
        <label for="player.seekbar_type" trans>Seekbar Type</label>
        <select name="player.seekbar_type" id="player.seekbar_type" [(ngModel)]="state.client['player.seekbar_type']">
            <option value="waveform" trans>Waveform</option>
            <option value="line" trans>Simple Bar</option>
        </select>
        <p trans>This will only affect tracks uploaded by users.</p>
    </div>

    <div class="input-container">
        <label for="player.default_artist_view" trans>Default Artist Albums View</label>
        <select name="player.default_artist_view" id="player.default_artist_view" [(ngModel)]="state.client['player.default_artist_view']">
            <option value="list" trans>List</option>
            <option value="grid" trans>Grid</option>
        </select>
        <p trans>How should albums on main artist page be displayed by default.</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.show_upload_btn" id="player.show_upload_btn" [(ngModel)]="state.client['player.show_upload_btn']" trans>Upload Button</mat-slide-toggle>
        <p trans>Whether upload button should be shown in left sidebar (Assuming user has permissions to upload tracks).</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.track_comments" id="player.track_comments" [(ngModel)]="state.client['player.track_comments']" trans>
            Track Comments
        </mat-slide-toggle>
        <p trans>Whether comments functionality should be enabled on track pages.</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.enable_download" id="player.enable_download" [(ngModel)]="state.client['player.enable_download']" trans>Enable Download Functionality</mat-slide-toggle>
        <p trans>Only works for custom uploaded tracks.</p>
    </div>

    <div class="setting-toggle-container margin-top">
        <mat-slide-toggle name="player.hide_queue" id="player.hide_queue" [(ngModel)]="state.client['player.hide_queue']" trans>Hide Queue</mat-slide-toggle>
        <p trans>Should player queue (right sidebar) be hidden by default.</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.hide_video" id="player.hide_video" [(ngModel)]="state.client['player.hide_video']" trans>Hide Video</mat-slide-toggle>
        <p trans>Should small video in the bottom right corner be hidden by default.</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.hide_lyrics" id="player.hide_lyrics" [(ngModel)]="state.client['player.hide_lyrics']" trans>Hide Lyrics Button</mat-slide-toggle>
        <p trans>Hide "Lyrics" button in player controls bar.</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.hide_video_button" id="player.hide_video_button" [(ngModel)]="state.client['player.hide_video_button']" trans>Hide Video Button</mat-slide-toggle>
        <p trans>Hide video toggle button in player controls bar.</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.hide_radio_button" id="player.hide_radio_button" [(ngModel)]="state.client['player.hide_radio_button']" trans>Hide Radio Button</mat-slide-toggle>
        <p trans>Hide all "go to radio" buttons across the site.</p>
    </div>

    <div class="setting-toggle-container">
        <mat-slide-toggle name="player.enable_repost" id="player.enable_repost" [(ngModel)]="state.client['player.enable_repost']" trans>Enable Reposts</mat-slide-toggle>
        <p trans>Enable reposting functionality for albums and tracks.</p>
    </div>

    <button mat-raised-button color="accent" class="button primary update-button" type="submit" [disabled]="loading$ | async" trans>Update</button>
    <mat-progress-bar mode="indeterminate" color="accent" *ngIf="loading$ | async"></mat-progress-bar>
</form>
