<div class="container-fluid mb-3">
  <!-- <section *ngIf="carouselData">
    <div id="carouselExampleControls" class="carousel slide" data-bs-ride="carousel">
      <div class="carousel-inner">
        <div *ngFor="let caroImg of carouselData; let activeCheck = first;" [class.active]="activeCheck"
          class="carousel-item">
          <img src="assets/img/carousel.jpg" class="d-inline img-fluid" alt="...">
        </div>
      </div>
      <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls"
        data-bs-slide="prev">
        <span class="carousel-control-prev-icon" aria-hidden="true"></span>
        <span class="visually-hidden" trans>Previous</span>
      </button>
      <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls"
        data-bs-slide="next">
        <span class="carousel-control-next-icon" aria-hidden="true"></span>
        <span class="visually-hidden" trans>Next</span>
      </button>
    </div>
  </section> -->


  <div class="datatable responsive-table">
    <!-- <datatable-header pluralResourceName="title" [showSelectedActions]="false">
  </datatable-header> -->
    <table class="material-table">
      <thead>
        <tr class="customHeader">
          <!-- <th table-header-checkbox></th> -->
          <th table-sort-header="name" trans>Title</th>
          <th table-sort-header="artists.name" trans>Artist</th>
          <th table-sort-header="genres.name" trans>Genre</th>
          <th table-sort-header="bpm" trans>BPM</th>
          <th table-sort-header="key" trans>Key</th>
          <th table-sort-header="created_at" trans>Date</th>
        </tr>
      </thead>
      <tbody class="material-table" cdkDropList [cdkDropListDisabled]="!reorderEnabled"
        (cdkDropListDropped)="orderChanged.emit($event)">
        <ng-container *ngFor="let gaana of gaane$ | async; let _index = index">
          <tr [class.selected]="selectedTracks.has(gaana)" [attr.data-id]="gaana.id"
            [class.playing]="trackIsPlaying(gaana)" class="track-list-row" cdkDrag>
            <td><a href="javascript:void(0)" class="d-flex align-items-center text-truncate" style="max-width: 200px;"
                trans>
                <!-- <i class="bi bi-play-circle-fill play-circle-icon"></i> -->
                <playback-control-button class="small me-2 control-circle" [track]="gaana" (play)="playTrack(gaana,_index)"
                  (pause)="player.pause()"></playback-control-button>
                {{gaana?.name}}
              </a></td>
            <td data-label="First Name" class="text-truncate" style="max-width: 200px;" trans>
              {{gaana?.artists[0]?.name || '-'}}</td>
            <td data-label="Last Name" trans>{{gaana?.genres[0]?.name || '-'}}</td>
            <td data-label="Last Name" trans>{{gaana?.bpm || '-'}}</td>
            <td data-label="Last Name" trans>{{gaana?.t_key || '-'}}</td>
            <td data-label="Assigned At" trans>{{gaana?.date | formattedDate}}</td>
            <td><a data-bs-toggle="collapse" href="#collapseDownload{{_index}}"><i
                  class="bi bi-three-dots collapse-icon"></i></a>
            </td>
          </tr>
          <tr class="--custom-height-unset">
            <td colspan="7" class="p-0">
              <div class="collapse" id="collapseDownload{{_index}}">
                <table class="table modal-details">
                  <tr class="text-white">
                    <td class="downloaded-song" trans>{{gaana?.name}}</td>
                    <td trans>
                      <i [ngClass]="isDownloaded(1, gaana.user_downloads)"
                        class="bi bi-circle-fill song-download-circle"></i>
                      <i [ngClass]="isDownloaded(2, gaana.user_downloads)"
                        class="bi bi-circle-fill song-download-circle"></i>
                      <i [ngClass]="isDownloaded(3, gaana.user_downloads)"
                        class="bi bi-circle-fill song-download-circle"></i>
                    </td>
                    <td>
                      <a (click)="downloadTrack(gaana?.id, _index)" href="javascript:void(0)"><i
                          class="bi bi-download song-download-icon"></i></a>
                    </td>
                  </tr>
                  <ng-container *ngIf="gaana?.track_versions.length > 0">
                    <tr *ngFor="let version of gaana?.track_versions; let verIndex = index;" class="text-white">
                      <td trans>{{version?.name}}</td>
                      <td>
                        <i [ngClass]="isVerDownloaded(1, version?.download_count)"
                          class="bi bi-circle-fill song-download-circle"></i>
                        <i [ngClass]="isVerDownloaded(2, version?.download_count)"
                          class="bi bi-circle-fill song-download-circle"></i>
                        <i [ngClass]="isVerDownloaded(3, version?.download_count)"
                          class="bi bi-circle-fill song-download-circle"></i>
                      </td>
                      <td>
                        <a (click)="downloadTrackVersion(gaana?.id, version?.id, _index, verIndex)"
                          href="javascript:void(0)"><i class="bi bi-download song-download-icon"></i></a>
                      </td>
                    </tr>
                  </ng-container>
                  <!-- <tr class="border-top-0 ">
                <td> Do It To It - Kwest vs Acraze Edit (Dirty)</td>
                <td><i class="bi bi-circle song-download-circle"></i> <i
                    class="bi bi-circle song-download-circle"></i> <i class="bi bi-circle song-download-circle"></i>
                </td>
                <td><a href="javascript:void(0)"><i class="bi bi-download song-download-icon"></i></a></td>
              </tr> -->
                </table>
              </div>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </table>
    <datatable-footer></datatable-footer>
  </div>
</div>
