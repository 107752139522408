import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
@Injectable({
  providedIn: 'root'
})
export class ComingSoonServiceService {
  ipAddress:any;
  constructor(private http: HttpClient) { }
  getIpCliente() {
    return this.http.get('https://api-bdc.net/data/client-ip');
  }
}
