<div class="modal-header">
    <h2 class="modal-title" mat-dialog-title trans>
        <span *ngIf="updating" trans>Update Genre</span>
        <span *ngIf="!updating" trans>New Sub Genre</span>
    </h2>
    <button type="button" (click)="close()" tabindex="-1" class="close-button no-style icon-button">
        <mat-icon svgIcon="close"></mat-icon>

    </button>
</div>

<form (ngSubmit)="confirm()" [formGroup]="form">
    <mat-dialog-content class="many-inputs">
        <div class="input-container">
            <label for="genre-name" trans>Name</label>
            <input type="text" id="genre-name" formControlName="name" required>
            <div class="error" *ngIf="formSubmit && iserror()">This is a required field.</div>
        </div>
        <div class="input-container" style="display:none">
            <label for="user" trans>Parent Gener</label>
            <input type="text" id="user" placeholder="Search for user..." [formControl]="userAutocomplete" [matAutocomplete]="auto" required>
            <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
                <mat-option *ngFor="let user of filteredUsers | async" [value]="user">{{ user.name }}</mat-option>
            </mat-autocomplete>
            <div class="error" *ngIf="formSubmit && isgenrror()">This is a required field.</div>
        </div>
       <!--  <div class="input-container">
            <label for="genre-display-name" trans>Display Name</label>
            <input type="text" id="genre-display-name" name="display_name" [(ngModel)]="genre.display_name" required>
            <p trans>Will be used across the site. If not provided, name will be used instead.</p>
            <div class="error" *ngIf="errors.display_name">{{errors.display_name}}</div>
        </div> -->
        <!-- <div class="input-container image-input">
            <label for="image" trans>Image</label>
            <div class="inline-container">
                <button type="button" mat-flat-button color="accent" (click)="openUploadImageModal()" [matTooltip]="'Upload genre image' | trans">
                    <mat-icon svgIcon="file-upload"></mat-icon>
                </button>
                <input type="text" id="image" name="image" [(ngModel)]="genre.image">
            </div>
            <upload-progress-bar></upload-progress-bar>
            <p trans>Upload genre image or enter image url.</p>
            <div class="error" *ngIf="errors.url">{{errors.url}}</div>
        </div> -->
    </mat-dialog-content>

    <mat-dialog-actions>
        <button (click)="close()" type="button" mat-button class="cancel-button" trans>Close</button>
        <button type="submit" mat-raised-button color="accent" class="submit-button" trans>
            <span *ngIf="updating" trans>Update</span>
            <span *ngIf="!updating" trans>Create</span>
        </button>
    </mat-dialog-actions>

    <loading-indicator [isVisible]="loading" class="overlay opacity-high"></loading-indicator>
</form>
